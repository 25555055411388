export const Overview = {
  title: `What We're Doing`,
  contents: [
    {
      title: `Guam Power Authority`,
      ul: [
        `Established May 1968 by the Guam Power Authority Act of 1968. See, Guam Code 12, Chapter 8.`,
        `GPA is a public corporation and an enterprise fund of the Government of Guam.`,
        `GPA's governing board is the Consolidated Commission on Utilities (CCU) consisting of five elected commissioners.`,
        `GPA's rates are regulated by the Guam Public Utilities Commission.`,
      ],
    },
    {
      title: `Employees and Customers`,
      ul: [
        `408 Employees (FY2023)`,
        `52,642 Customers (FY2023)`,
        `Fiscal Years Begin on October 1st and Ends on September 30th`,
        `FY 2023: October 1, 2022 through September 30, 2023`,
        `FY 2023 approved budget with fuel: $647M (as amended)`,
        `Bond Ratings: Fitch, BBB/Stable; Moody's Baas2/Stable; S&P, BBB/Stable`,
      ],
    },
    {
      title: `Major Plant Assets`,
      ul: [
        `464 MW Oil-fired Generation Capacity`,
        `85.3 MW Renewable Generation Capacity`,
        `1,839 Miles Combined Transmission & Distribution Lines`,
        `29 Substations`,
      ],
    },
    {
      title: `Major Fuel Types`,
      ul: [`Ultra-Low Sulfur Diesel (ULSD)`, `0.2%S Low Sulfur Residual Fuel Oil (LSRFO)`],
    },
  ],
};

export const MissionVisionFactors = {
  contents: [
    {
      title: `GPA Mission Statement`,
      description: `GPA SHALL provide`,
      ol: [
        `R  eliable`,
        `E3 fficient, Effective, Environmentally Sound`,
        `A2 ffordable, Accountable`,
        `L  eading Energy Solutions`,
      ],
    },
    {
      title: `Vision Statement`,
      description: `GPA will be the best utility providing outstanding energy solutions to our island community.`,
      ol: [],
    },
    {
      title: `Critical Success Factors`,
      description: ``,
      ol: [
        `Commit to Product Affordability & Best Value`,
        `Achieve Superior Customer Service`,
        `Achieve High System Reliability`,
        `Become Financially Sound and Stable`,
        `Commit to Workforce Development & Outstanding Leadership`,
        `Protect Our Island Environment`,
        `Commit to Effective Use of Technology`,
      ],
    },
  ],
};

export const Documents = {
  contents: [
    {
      title: `Authority Policies`,
      initial: `P.L. No. 34-5.pdf`,
      description: `“In compliance with Public Law 34-05, relative to require policies/procedures to be posted on the Agency’s website.”`,
      ul: [],
    },
    {
      title: `Engineering`,
      initial: ``,
      description: ``,
      ul: [
        `AP-025 Standardization Committee.pdf`,
        `AP-066 Temporary Power Installation.pdf`,
        `AP-072 Net Metering Program Interconnection Policy.pdf`,
      ],
    },
    {
      title: `Internal Audit`,
      initial: ``,
      description: ``,
      ul: [
        `SP-121 Meter Tampering-Illegal Hookup - Investigating & Rptng Procedures (REV) 1.04.19.pdf`,
        `SP-141 Procedures for the Conduct of Internal Audits 7.02.19.pdf`,
        `AP-073 Charter of the GPA Internal Audit Office REVISED 3_21_19.pdf`,
      ],
    },
    {
      title: `Planning & Regulatory Division`,
      initial: ``,
      description: ``,
      ul: [`SOP-052 Used Oil Transfer to Used Oil Facility.pdf`],
    },
    {
      title: `Supply Management Administration`,
      initial: ``,
      description: ``,
      ul: [
        `AP-081 Issuance Procedure of Non-Inventory Tools and or Equipment.pdf`,
        `AP-075 Inventory Obsolescence Policy.pdf`,
        `AP-027 Supply Management.pdf`,
      ],
    },
  ],
};

export const Officials = {
  johnBenevente: [
    {
      title: `JOHN M. BENAVENTE, P.E.`,
      position: `General Manager,`,
      company: `Guam Power Authority`,
      paragraph1: `Buenas yan Hafa Ådai,`,
      paragraph2: `As we commemorate 55 years of service to our island, I especially acknowledge and thank you, the hard-working men and women at GPA, as well as our retired employees who all, in one way or another, contributed to the growth and success of our island’s sole electric power utility, which benefits our ratepayers and entire island community. Each one of you helped improve the quality of life for all of us who call Guam home.`,
      paragraph3: `Looking back over the years, GPA reflects on its growth and perseverance through the many changes, hardships, and challenges impacting its operations as Guam’s sole provider of electricity. `,
      paragraph4: `Advancing five years forward from GPA’s 50th anniversary milestone, unexpected and various issues impacted all GPA operations. The global health crisis of the COVID-19 Pandemic necessitated changes to GPA’s plant, field, business, and customer services operations. GPA acted quickly and responsibly, implementing various employee and customer public health protections and by pivoting commercial and residential customer service delivery. Online, mobile app, and Pay-by-Phone service features were implemented for customer convenience and safety. Additionally, the GPA Fadian location opened a new Drive-Thru payment option for contactless customer servicing. Additionally, GPA offered payment plan options to help keep customers’ electrical service flowing and their lights on. Service disconnections were also suspended temporarily. Staff ingenuity and dedication, and the support of their families made the impossible possible during this unprecedented and challenging time.`,
      paragraph5: `Furthermore, GPA has made strides in order to move towards reducing the carbon footprint, establishing the Integrated Resource Plan (IRP), also known as the Clean Energy Master Plan (CEMP), to help shape and prepare the power system for the next generation. CEMP is a comprehensive plan for transitioning Guam from legacy fossil fuel fired generation to renewable energy and non-greenhouse gas emission electric energy supply. GPA is committed to providing 50% of the island’s electric power from renewable or non-greenhouse gas emissions sources by 2030 and 100% by 2040. The CEMP is a living document and continuously being updated with the most recent update to the plan completed and approved by the Consolidated Commission on Utilities (CCU) and Public Utilities Commission (PUC) in 2022. It is GPA’s roadmap to a clean, reliable, affordable, and resilient energy future. It identifies about $230M in upgrades and new technology to integrate renewables.`,
      paragraph6: `Furthermore, GPA has made strides in order to move towards reducing the carbon footprint, establishing the Integrated Resource Plan (IRP), also known as the Clean Energy Master Plan (CEMP), to help shape and prepare the power system for the next generation. CEMP is a comprehensive plan for transitioning Guam from legacy fossil fuel fired generation to renewable energy and non-greenhouse gas emission electric energy supply. GPA is committed to providing 50% of the island’s electric power from renewable or non-greenhouse gas emissions sources by 2030 and 100% by 2040. The CEMP is a living document and continuously being updated with the most recent update to the plan completed and approved by the Consolidated Commission on Utilities (CCU) and Public Utilities Commission (PUC) in 2022. It is GPA’s roadmap to a clean, reliable, affordable, and resilient energy future. It identifies about $230M in upgrades and new technology to integrate renewables.`,
      paragraph7: `Today, all GPA power plants operate burning cleaner fuels to comply with the USEPA national emission standards. The conversion to cleaner fuel is part of GPA’s Clean Energy Master Plan and satisfies a Federal Consent Decree that required all of Guam’s generators to convert to cleaner burning fuel. The Cabras 1 & 2 baseload units run on 0.2% low sulfur residual fuel oil and the Piti 8 & 9 units are now running on ultra-low sulfur fuel. The conversion has significantly reduced overall emission resulting in cleaner air and environment-friendly plant operations.`,
      paragraph8: `In 2019, GPA signed an energy conversion agreement (ECA) with Korea Electric Power Corporation (KEPCO) to build a clean and efficient base load power plant. The project started in July 2022, with scheduled commissioning planned for 2024. When operational, the new Ukudu power plant will allow for a reduced, affordable fuel LEAC rate, and provide 198 megawatts of new capacity to improve reliability and strengthen the power grid system. The technology of the Ukudu base load power plant will allow GPA to burn cleaner ultra-low sulfur diesel (ULSD) and liquefied natural gas (LNG) fuels, resulting in the saving of millions of dollars in reduced fuel costs, and will reduce SO2 emissions. This will then allow for the retirement of GPA’s older and less-efficient power plants.`,
      paragraph9: `Operational since March 2021, GPA’s world class utility-scale battery energy storage system (BESS) has assisted in eliminating most of the short duration power outages caused by generator and renewable intermittency trips. The system works to smooth out the fluctuation solar output and monitor the frequency, reacting accordingly to stabilize the island-wide grid system.`,
      paragraph10: `In July 2022, KEPCO Mangilao Solar, LLC (KMS) and GPA commissioned the 60 megawatts solar photovoltaic (PV) farm in Mangilao. The 141,000,000 kWh contracted from KMS each year will produce energy for 14,000 residential customers for an entire year. The solar farm is another important step toward reducing Guam’s reliance on imported oil, estimated at 300,000 barrels in the first year. This addition marks a total of 85 megawatts of utility scale solar onto GPA’s power grid.`,
      paragraph11: `The U.S. Navy has been a customer of GPA for over 30 years. In July 2022, the U.S. Navy extended its contract with GPA as the primary power provider for Guam’s Naval Base and Naval Installations for an additional 10 years. The signing signifies a continuing partnership and confidence in Guam’s energy future. The Navy-GPA agreement allows the Navy to transfer power assets including substations and transmission and distribution lines to GPA at no cost, ultimately benefitting GPA’s ratepayers. The U.S. Navy has now been a customer of GPA for over 30 years.`,
      paragraph12: `GPA’s energy delivery system is managed through a Power System Control Center and Supervisory Control and Data Acquisition (SCADA) system. GPA continues to make investments to strengthen and improve the utility infrastructure by installing hardened power poles, constructing concrete housings for critical substations, to placing vital power lines underground of which key businesses and high-density residential areas are serviced by underground transmission systems. The extension of 115kV transmission lines into southern Guam is being pursued and a significant inventory of essential materials, parts and equipment is maintained on island. GPA invested in standby generators for critical water and wastewater assets, invested in advanced meter infrastructure thru smart grid technology to modernize infrastructure, reduce costs and increase customer satisfaction. Through GPA’s smart meter technology customers can monitor and control their power consumption through MyEnergyGuam. GPA continues to invest in its fleet to ensure it has adequate supply of heavy and light vehicles. GPA deploys 25 bucket trucks in its fleet. In 2023, additional hybrid electric bucket trucks will be added, designed to help lower fleet vehicle emissions. GPA transitioned to newer technology, installing LED streetlights. The LED lights are brighter, have a longer lifespan and are more energy efficient, resulting in cost savings for ratepayers, and contributes to improved safety and higher visibility for our island community.`,
      paragraph13: `For several years now, GPA has implemented the Energy Sense Rebate Program that offers cash rebates for customers purchasing more energy-efficient home appliances. The program encourages conservation and lower energy consumption. Over 31,000 rebate applications have been processed, and over $9M in rebates have been paid out since 2015. In August 2022, GPA’s rebate application process was automated. The online rebate application process improves the overall accuracy, efficiency, and processing of rebates and provides the ability to track the progress of rebate applications online. It saves time and can easily be done at the comfort and convenience of the customer’s home. Through its customer engagement and energy sense programs, GPA has provided customer assistance and outreach programs to the community. The programs offer energy conservation and energy savings tips to assist ratepayers in managing their energy consumption, and achieving savings on their electric power bills.`,
      paragraph14: `GPA’s new website was launched in early 2023 allowing customers to experience a new and improved user-friendly online experience. The website is designed to be a robust online tool that allows GPA to better serve its commercial and residential customers.`,
      paragraph15: `To address workforce attrition and the shortage of utility skilled workers, GPA implemented a successful apprenticeship & training program. GPA's rigorous registered apprenticeship program trains and develops highly-skilled apprentices to become journeymen electric linemen. The apprentices must successfully complete 6,000 hours of on-the-job instruction with certified electric tradesmen and 144 hours of annual classroom training. Approximately 33% of GPA's current electric journeymen graduated from the apprenticeship program. GPA’s apprentices gain the specialized skills needed in the power industry locally and worldwide. GPA’s internship programs have been established in several key areas including Engineering, Finance, and Information Technology.`,
      paragraph16: `GPA’s vision and mission support greener energy solutions in the utility’s transition to adding additional renewable energy technologies, to include continuing to promote energy conservation and efficiency for both commercial and residential ratepayers. 
      In this 55th year, and in the years to come, the leadership and employees of the Guam Power Authority, will deliver GPA’s mission. Familian GPA are the more than 400 hardworking employees who are committed to powering our island and keeping the lights on. They convey the resilience, determination, ingenuity, innovativeness, and hardworking spirit necessary in performing their work professionally, for the people of Guam.
      `,
      paragraph17: `This year, GPA also reflects on its history and having transitioned over the years from appointed board governance to today’s elected Consolidated Commission on Utilities (CCU). The CCU commissioners, directly responsible and accountable to the voters of Guam, is now in its 21st year serving as GPA’s governing board. Their combined talent and professional expertise guides and results in excellent policy decisions for our utility, the ratepayers, and the community.
      Biba GPA! Biba Guam!
      `,
      paragraph18: `John M. Benavente, P.E.`,
      paragraph19: `General Manager, Guam Power Authority`,
    },
  ],
  melindaMafnas: [
    {
      title: `MELINDA MAFNAS`,
      position: `Assistant General, Manager of Operations,`,
      company: `Guam Power Authority`,
      paragraph1: `Ms. Mafnas is a Guam registered professional electrical engineer with more than 20 years of electric utility experience. She is a local Guamanian (Chamorro) who received her secondary education from the Academy of Our Lady of Guam. She received her Bachelor of Science degree in Electrical Engineering in 1991 from the University of Hawaii at Manoa. In September 2010, Ms. Mafnas assumed her present position as the Acting Assistant General Manager of Operations for GPA where she manages the operations of Generation, Transmission & Distribution, SCADA/Dispatching, Engineering, and auxiliary divisions in support of these operations.`,
      paragraph2: `She held the position of Manager of Operations Division before being named the Assistant General Manager of Operations in 2011. Melinda Mafnas is the first woman to serve as Assistant General Manager of Operations for the Guam Power Authority.`,
      paragraph3: ``,
      paragraph4: ``,
      paragraph5: ``,
    },
  ],
};

export const Reports = {
  contents: [
    {
      title: `GM PERFORMANCE`,
      heading: `The Consolidated Commission on Utilities`,
      description: `“Herein provides public notice pursuant to 5 GCA § 43203, of the public availability of the Annual Performance Review of the General Manager of the Guam Power Authority that took place on December 3, 2020. The performance review is available in full on the Authority’s website and on the Consolidated Commission on Utilities website.”`,
      ul: [
        `2019 CCR;`,
        `2019 Annual Report`,
        `GM Performance Presentation 2015/2020`,
        `GM Performance Evaluation 2018/2020`,
        `GM Performance Evaluation 2021`,
      ],
    },
    {
      title: `CCU SPECIAL MEETING 11.10.20 AUDIO RECORDINGS`,
      initial: ``,
      description: ``,
      ul: [
        `CCU Sp. Mtg 11.10.20 Contd 12.3.20_Reg1.MP3`,
        `CCU Sp. Mtg 11.10.20 Contd 12.3.20_Reg2.MP3`,
        `CCU Sp. Mtg 11.10.20 Contd 12.3.20_Reg3.MP3`,
        `CCU Sp. Mtg 11.10.20 Contd 12.3.20_Reg4.MP3`,
      ],
    },
  ],
};

export const Standards = {
  contents: [
    {
      title: `PERFORMANCE`,
      description: `Guam Power Authority management holds strongly to the tenet that if you do not measure performance, then you cannot manage performance. In order to better serve the public, the Authority maintains standards for performance. In addition, the PUC and GPA have agreed upon standards for prudent fuel use.`,
    },
    {
      title: `Prudent Fuel Use Standards`,
      description: `Guam Power Authority and the Guam Public Utilities Commission, as the result of a collaborative effort, have agreed on a set of standards, practices, procedures and methodologies to promote and provide incentives for prudent fuel use.`,
    },
  ],
};

export const DepartmentNumbers = {
  title: `Department Numbers`,
  contents: [
    {
      ul: [
        {
          anchor: `The Official Portal for the Island of Guam`,
          link: `https://www.guam.gov/`,
        },
        {
          anchor: `Office of the Governor of Guam`,
          link: `http://governor.guam.gov`,
        },
        {
          anchor: `Guam Delegate to the U.S. Congress`,
          link: `https://sannicolas.house.gov/`,
        },
        {
          anchor: `The Guam Legislature / Liheslaturan Guahan`,
          link: `https://www.guamlegislature.com/`,
        },
        {
          anchor: `The Attorney General of Guam`,
          link: `http://oagguam.org/`,
        },
        {
          anchor: `Guam Public Utilities Commission`,
          link: `https://guampuc.com/`,
        },
        {
          anchor: `Consolidated Commission on Utilities`,
          link: `https://guamccu.org/`,
        },
      ],
    },
    {
      ul: [
        {
          anchor: `The Guam Waterworks Authority`,
          link: `http://www.guamwaterworks.org/`,
        },
        {
          anchor: `Guam Judiciary`,
          link: `http://www.guamsupremecourt.com/`,
        },
        {
          anchor: `Guam Public Accountability (OPA)`,
          link: `http://www.opaguam.org/`,
        },
        {
          anchor: `Guam Department of Eduation`,
          link: `http://www.gdoe.net/`,
        },
        {
          anchor: `Guam Community College`,
          link: `http://guamcc.edu/`,
        },
        {
          anchor: `Guam Chamber Of Commerce`,
          link: `http://www.guamchamber.com.gu/`,
        },
        {
          anchor: `GCA Complier Of Laws`,
          link: `http://www.guamcourts.org/CompilerofLaws/gca.html`,
        },
      ],
    },
    {
      ul: [
        {
          anchor: `Guam Organic Act`,
          link: `http://www.guamcourts.org/CompilerofLaws/GCA/OrganicAct/Organic%20Act.PDF`,
        },
        {
          anchor: `University Of Guam`,
          link: `http://www.uog.edu/`,
        },
        {
          anchor: `PEACE: Prevention Education & Community Empowerment`,
          link: `http://www.peaceguam.org/`,
        },
        {
          anchor: `Guam State Clearinghouse`,
          link: `http://gsc.guam.gov/`,
        },
        {
          anchor: `Guam Solid Waste Authority`,
          link: `https://www.online-billpay.com/default.aspx?CompanyName=SOLID_WASTE_MANAGEMENT`,
        },
        {
          anchor: `Guam Homeland Security/Office of Civil Defense`,
          link: `https://ghs.guam.gov/`,
        },
        {
          anchor: `Guam Department of Public Health and Social Services`,
          link: `http://dphss.guam.gov/`,
        },
      ],
    },
  ],
};
