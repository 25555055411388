import { HeaderGradient } from '@components';
import React from 'react';
import { Content as ContentContainer, Details, H2, H3, Images } from '@core';
import {
  BusinessHoursAndLocations,
  // CustomerServiceCenterAndMainOffice,
  // CustomerServices,
  // DepartmentFaxNumbers,
  // DepartmentNum,
  // EmailCustomerService,
  // GenerationAdministration,
  // GenerationAdministrationFaxNumbers,
  // GpwaUpperTumonOffice,
  // HagatnaSatelliteOffice,
  // MailingAddresses,
  // Other,
  // PayByPhone,
  // PaymentCenter,
  // PayOnline,
  // TransmissionAndDistribution,
  // Transportation,
  WaysToPayMethod,
} from '@content';

export const WaysToPay = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Ways to Pay Your Power Bill"
      />
      <ContentContainer>
        <section>
          <div>
            <H2>{WaysToPayMethod.methodArray[0].method}</H2>
            <Details>
              Visit{' '}
              <a className="text-orange" href="https://paygpa.com/">
                PayGPA.com
              </a>{' '}
              or Download the Pay GPWA App. You can also pay your GWA water bill on the app.
              <div className="block w-24 mt-3">
                <img alt="Pay GPWA Logo" className="block w-full" src={Images.PayGPWALogo} />
              </div>
              <p className="mt-2">
                Download the app from{' '}
                <a
                  className="text-orange"
                  href="https://apps.apple.com/us/app/pay-gpwa/id1593835535"
                  rel="noreferrer"
                  target="_blank"
                >
                  Apple Store
                </a>
                .
              </p>
              <p className="mt-2">
                Download the app from{' '}
                <a
                  className="text-orange"
                  href="https://play.google.com/store/apps/details?id=com.xtendly.paygpwa&hl=en&gl=US"
                  rel="noreferrer"
                  target="_blank"
                >
                  Google Play
                </a>
                .
              </p>
            </Details>
          </div>
          <div>
            <H2>{WaysToPayMethod.methodArray[1].method}</H2>
            <Details>
              To make a GPA payment over the phone, please contact (671) 647-5787/8/9,
              <br /> Option 2. <br /> Mon-Fri 7:30am to 5pm <br />{' '}
              <span className="text-blue">Afterhours</span> 1 (855) 712-9696 Toll Free
            </Details>
          </div>
          <div>
            <H2>{WaysToPayMethod.methodArray[2].method}</H2>
            <Details>
              Make Check payable to Guam Power Authority and mailed to: P.O. Box 21868 Barrigada, GU
              96921
            </Details>
          </div>
          <div>
            <H2>{WaysToPayMethod.methodArray[3].method}</H2>
            <Details>
              {WaysToPayMethod.methodArray[3].description[0]}
              <H3 className="inline">{WaysToPayMethod.methodArray[3].description[1]}</H3>
              {WaysToPayMethod.methodArray[3].description[2]}
            </Details>
          </div>
          <div>
            <H2>{WaysToPayMethod.methodArray[4].method}</H2>
            <Details>
              {/* <span className="text-blue">GPA Fadian</span>
              <br />
              Mon-Fri 7:30am to 3:30pm
              <br />
              <br /> */}
              <span className="text-blue">GPWA Upper Tumon Office</span>
              <br />
              Mon-Fri 7:30am to 3:30pm
              <br />
              Sat 8am to 12pm
            </Details>
          </div>
          <div>
            <H2>{WaysToPayMethod.methodArray[5].method}</H2>
            <Details>
              <span className="text-blue">Customer Service Center & Main Office</span> <br />
              GPA Fadian <br />
              Mon-Fri 7am to 5pm <br /> <br />
              <span className="text-blue">Hagatna Satellite Office</span> <br />
              Julale Shopping Center <br />
              Mon-Fri 8am to 5pm <br /> <br />
              <span className="text-blue">GPWA Upper Tumon Office</span> <br />
              Mon-Fri 7:30am to 6pm <br />
              Sat 8am to 12pm <br />
            </Details>
          </div>

          <div>
            <H2>All Treasurer of Guam Locations</H2>
            <Details>
              At GPA, you can also pay your GWA and GSWA trash bills.
              <br />
              Your Bank - be sure to check with your bank for their updated policies.
            </Details>
          </div>
        </section>
        <div className="business-hours-location" id="business-hours-and-location" />
        <H3>
          <a className="text-orange" href="/contact-us#hours-and-locations">
            {BusinessHoursAndLocations.title}
          </a>
        </H3>
      </ContentContainer>
    </>
  );
};
