import { API } from '@config';

export const getWebsiteNotificationData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let data;

  try {
    const response = await fetch(`${API}/api/website-notifications?sort[0]=updatedAt:desc`, options);
    data = await response.json();

    return data;
  } catch (err) {
    // console.error(err);
  }

  return data;
};