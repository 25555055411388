import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navbar, Footer } from '@components';
import { Navigations, DefaultsRoutes, SingleRoutes, SearchPageRoute } from '@constants';
import { Maintenance, PageNotFound } from './pages';
import WebsiteNotification from './components/WebsiteNotification';
// import ScrollToTop from './routes/ScrollToTop';

const App = () => {
  const location = useLocation();
  const [isScrollable, setIsScrollable] = useState(true);

  if(window.location.href === "https://guampowerauthority.com/home/home_home.php"){
    window.location.replace("https://guampowerauthority.com/");
  };

  if(window.location.href === "https://guampowerauthority.com/home/home_rate_calculator.php"){
    window.location.replace("https://guampowerauthority.com/rate-calculator");
  }

  if(window.location.href === "https://guampowerauthority.com/gpa_authority/EnergySense/es-about.html"){
    window.location.replace("https://energysense.guampowerauthority.com/");
  }
  if(window.location.href === "https://guampowerauthority.com/in-our-community/How%20Power%20is%20Restored%20After%20a%20Tropical%20Storm%20or%20Typhoon"){
    window.location.replace("https://admin.guampowerauthority.com//uploads/GPA_How_Power_Is_Restored_cea3652d5c.pdf");
  }
  if(window.location.href === "https://guampowerauthority.com/DAMAGE_CLAIM_FORM_10NOV2020.pdf"){
    window.location.replace("https://admin.guampowerauthority.com/uploads/DAMAGE_CLAIM_FORM_and_info_10_NOV_2020_c4e6f35eeb.pdf");
  }


  return (
    <div
      className={`container-1600px relative h-full bg-white font-rubik overflow-x-hidden ${
        isScrollable ? 'overflow-y-scroll' : 'overflow-y-hidden'
      }`}
    >
      {/* <ScrollToTop /> */}
      <WebsiteNotification />
      <Navbar toggleScrollable={setIsScrollable} />
      <Routes>
        {/* Defaults Routes */}
        {DefaultsRoutes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* Single Routes */}
        {SingleRoutes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* How do I Routes */}
        {Navigations[0].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* Residential Customers Routes */}
        {Navigations[1].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* Business Customers Routes */}
        {Navigations[2].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* Rates Customers Routes */}
        {Navigations[3].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* In Our Community Routes */}
        {Navigations[4].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* GPA Initiatives Routes */}
        {Navigations[5].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* Corporate Routes */}
        {Navigations[6].routes.map((route) => {
          return <Route element={<route.page />} key={route.link} path={route.link} />;
        })}

        {/* Search Page Route */}
        {location.pathname === '/search' &&
          SearchPageRoute.map((route) => {
            return <Route element={<route.page />} key={route.link} path={route.link} />;
          })}
        
        {/* <Route element={<BrighterDaysAhead />} key="/brighterdaysahead" path="/brighterdaysahead" />; */}

        <Route element={<Maintenance />} path="/maintenance" />
        <Route element={<PageNotFound />} path="*" />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
