import React, { Fragment, useEffect, useState } from "react";
import { H1, H2, H3, Details, List, Content as ContentContainer } from "@core";
import { AnnualReports } from "@content";
import { HeaderGradient } from "@components";
import { Tab } from "@headlessui/react";
import { FINANCIALSANNNUALREPORTS } from "@src/types/FinancialsAnnualReports";
import { CORPORATEFINANCIALS } from "@src/types/CorporateFinancials";
import { getFinancialAnnualReport } from "@src/services/FinaincialsAnnualReport";
import { getFinancialAuditedStatementsArchive } from "@src/services/FinancialAuditedStatementsArchive";
import { getFinancialsUnAuditedStatementsArchive } from "@src/services/FinancialsUnAuditedStatementsArchive";
import { getFinancialsCitizenCentricReport } from "@src/services/FinancialsCitizenCentricReport";
import { getFinancialsBondStatements } from "@src/services/FinancialsBondStatements";
import { getFinancialsAssesmentReports } from "@src/services/FinancialsAssesmentReports";
// import { getFinancialFuelPurchases } from '@src/services/FinancialsFuelPurchases';
// import { getFinancialsUsefulInformations } from '@src/services/FinancialsUsefulInformations';
import { API } from "@src/config";
import { getFinancialRating } from "@src/services/FinancialCreditRating";
import { useQuery } from "@src/helpers/useQuery";
import { getNonEmployeeTravelJustificationReports } from "@src/services/NonEmployeeTravelJustificationReports";

export const Financials = () => {
  const query = useQuery();
  const [annualReport, setAnnualReport] = useState<FINANCIALSANNNUALREPORTS[]>(
    []
  );

  const [auditedStatements, setAuditedStatements] = useState<
    CORPORATEFINANCIALS[]
  >([]);
  const [unAuditedStatements, setUnAuditedStatements] = useState<
    CORPORATEFINANCIALS[]
  >([]);
  const [citizenCentricReports, setCitizenCentricReports] = useState<
    CORPORATEFINANCIALS[]
  >([]);
  const [bondStatements, setBondStatements] = useState<CORPORATEFINANCIALS[]>(
    []
  );
  // const [fy2015, setFy2015] = useState<CORPORATEFINANCIALS[]>([]);
  // const [fy2016, setFy2016] = useState<CORPORATEFINANCIALS[]>([]);
  // const [fy2017, setFy2017] = useState<CORPORATEFINANCIALS[]>([]);
  // const [fy2018, setFy2018] = useState<CORPORATEFINANCIALS[]>([]);
  // const [fy2019, setFy2019] = useState<CORPORATEFINANCIALS[]>([]);
  // const [fy2020, setFy2020] = useState<CORPORATEFINANCIALS[]>([]);
  const [assesmentReports, setAssesmentReports] = useState<
    CORPORATEFINANCIALS[]
  >([]);
  const [financialRatings, setFinancialRatings] = useState<
    Array<{
      year: string;
      items: CORPORATEFINANCIALS[];
    }>
  >([]);
  // const [fuelPurchases, setFuelPurchases] = useState<CORPORATEFINANCIALS[]>([]);
  // const [usefulInformation, setUsefulInformation] = useState<CORPORATEFINANCIALS[]>([]);

  // const [nonEmployeeTravelJustificationReports, setNonEmployeeTravelJustificationReports] = useState<
  //   CORPORATEFINANCIALS[]
  // >([]);
  const [nonEmployeeTravelJustificationReportsNew, setNonEmployeeTravelJustificationReportsNew] = useState<
    CORPORATEFINANCIALS[]
  >([]);
  const [nonEmployeeTravelJustificationReportsArchive, setNonEmployeeTravelJustificationReportsArchive] = useState<
    CORPORATEFINANCIALS[]
  >([]);

  const normalizeFinancialRatingsData = (
    financialRatingsData: CORPORATEFINANCIALS[]
  ): Array<{
    year: string;
    items: CORPORATEFINANCIALS[];
  }> => {
    const normalizedData: Array<{
      year: string;
      items: CORPORATEFINANCIALS[];
    }> = [];
    financialRatingsData.forEach((financialRating) => {
      const financialRatingYear =
        financialRating.attributes.credit_rating_year.data.attributes.Year;
      if (
        normalizedData.findIndex((dta) => dta?.year === financialRatingYear) ===
        -1
      ) {
        normalizedData.push({
          year: financialRatingYear,
          items: [financialRating],
        });
      } else {
        normalizedData.forEach((dta) => {
          if (dta.year === financialRatingYear) {
            dta?.items.push(financialRating);
          }
        });
      }
    });
    return normalizedData;
  };

  const getData = async () => {
    const annualReportData = await getFinancialAnnualReport();
    const auditedStatementsData = await getFinancialAuditedStatementsArchive();
    const unAuditedStatementsData =
      await getFinancialsUnAuditedStatementsArchive();
    const citizenCentricReportsData = await getFinancialsCitizenCentricReport();
    const nonEmployeeTravelJustificationReportsData = await getNonEmployeeTravelJustificationReports();
    const bondStatementsData = await getFinancialsBondStatements();
    const assesmentReportsData = await getFinancialsAssesmentReports();
    const financialRatingsData = await getFinancialRating();
    // const fuelPurchasesData = await getFinancialFuelPurchases();
    // const usefulInformationData = await getFinancialsUsefulInformations();
    const normalizedFRData = normalizeFinancialRatingsData(
      financialRatingsData.data.reverse()
    );

    // Sort Data by Anchor (Year - Month) Ex. "FY2024 - January"
    if(nonEmployeeTravelJustificationReportsData.data.length > 0){
      const monthList = [
        "October",
        "November",
        "December",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
      ];

      const currentNonEmployeeTravelJustificationReportsData: CORPORATEFINANCIALS[] = nonEmployeeTravelJustificationReportsData.data;
      currentNonEmployeeTravelJustificationReportsData.sort((a, b) => {
        const itemListA: any = a.attributes.Anchor.split(" - ");
        const yearA = itemListA.length > 0 ? itemListA[0].replaceAll("FY", "") : "0";
        const monthA = itemListA.length > 1 ? itemListA[1] : "January";
        const itemListB: any = b.attributes.Anchor.split(" - ");
        const yearB = itemListB.length > 0 ? itemListB[0].replaceAll("FY", "") : "0";
        const monthB = itemListB.length > 1 ? itemListB[1] : "January";

        // Sort by year first
        if (parseInt(yearA, 10) !== parseInt(yearB, 10)) {
          return parseInt(yearB, 10) - parseInt(yearA, 10); // Descending
          // return parseInt(yearA, 10) - parseInt(yearB, 10); // Ascending
        }
        // If years are the same, sort by month
        return monthList.indexOf(monthB) - monthList.indexOf(monthA); // Descending
        // return monthList.indexOf(monthA) - monthList.indexOf(monthB); // Ascending
      });
      
      // Filter NonEmployeeTravelJustificationReports 2024 from Archive
      const filteredNonEmployeeTravelJustificationReportsNew = currentNonEmployeeTravelJustificationReportsData.filter((item) => item.attributes.Anchor.includes("2025"));
      const filteredNonEmployeeTravelJustificationReportsArchive = currentNonEmployeeTravelJustificationReportsData.filter((item) => !item.attributes.Anchor.includes("2025"));
      
      // setNonEmployeeTravelJustificationReports(currentNonEmployeeTravelJustificationReportsData);
      setNonEmployeeTravelJustificationReportsNew(filteredNonEmployeeTravelJustificationReportsNew);
      setNonEmployeeTravelJustificationReportsArchive(filteredNonEmployeeTravelJustificationReportsArchive);
    }

    setFinancialRatings(normalizedFRData);
    setAnnualReport(annualReportData.data);
    setAuditedStatements(auditedStatementsData.data);
    setUnAuditedStatements(unAuditedStatementsData.data);
    setCitizenCentricReports(citizenCentricReportsData.data);
    setBondStatements(bondStatementsData.data);
    // setFy2015(fy2015Data.data);
    // setFy2016(fy2016Data.data);
    // setFy2017(fy2017Data.data);
    // setFy2018(fy2018Data.data);
    // setFy2019(fy2019Data.data);
    // setFy2020(fy2020Data.data);
    setAssesmentReports(assesmentReportsData.data);
    // setFuelPurchases(fuelPurchasesData.data);
    // setUsefulInformation(usefulInformationData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const clickItem = (link?: string, file?: any) => {
    if (file != null) {
      window.open(`${API}${file}`);
    } else {
      window.open(`${link}`);
    }
  };

  // console.log(fuelPurchases, usefulInformation);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={AnnualReports.title}
      />
      <ContentContainer className="-mb-20">
        {/* <div className="flex laptop:flex-row phone:flex-col"> */}
        <div className="flex flex-col">
          <section className="mb-20 tablet:mb-10">
            <Tab.Group
              defaultIndex={
                // eslint-disable-next-line no-nested-ternary
                query.get("tab") === "ar"
                  ? 2
                  : query.get("tab") === "ccr"
                    ? 1
                    : 0
              }
            >
              <Tab.List className="flex justify-left border-b tab-list-style overflow-x-auto">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${selected
                          ? "bg-blue text-white"
                          : "bg-lightgray text-blue"
                        } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      FINANCIAL STATEMENTS
                    </div>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${selected
                          ? "bg-blue text-white"
                          : "bg-lightgray text-blue"
                        }  py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      CITIZEN CENTRIC REPORTS
                    </div>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${selected
                          ? "bg-blue text-white"
                          : "bg-lightgray text-blue"
                        } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      ANNUAL REPORTS
                    </div>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${selected
                          ? "bg-blue text-white"
                          : "bg-lightgray text-blue"
                        } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      BONDS & CREDIT RATINGS
                    </div>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${selected
                          ? "bg-blue text-white"
                          : "bg-lightgray text-blue"
                        } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      Non-Employee Travel Justification
                    </div>
                  )}
                </Tab>
                {/* <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${
                        selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                      } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      MANAGEMENT AND OPERATIONAL ASSESSMENT
                    </div>
                  )}
                </Tab> */}
              </Tab.List>
              <Tab.Panels className="">
                <Tab.Panel>
                  <div className="my-10">
                    <Tab.Group>
                      <Tab.List className="flex items-center justify-left border-b">
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Audited Financial Statements
                            </div>
                          )}
                        </Tab>
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Un-Audited Financial Statements & Statement of Revenues and Expenses
                            </div>
                          )}
                        </Tab>
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Self-Insurance Protocols
                            </div>
                          )}
                        </Tab>
                      </Tab.List>
                      <Tab.Panels className="">
                        <Tab.Panel>
                          <section className="my-5">
                            <H2 className="mb-5 text-blue">
                              Audited Financial Statements
                            </H2>
                            <div className="financial-statement-columns">
                              {/* <UnorderedList> */}
                              {auditedStatements.map((value) => {
                                return (
                                  <List
                                    className="text-orange whitespace-nowrap"
                                    key={value.attributes.createdAt}
                                  >
                                    {value.attributes.Anchor != null ? (
                                      <button
                                        className="text-left"
                                        onClick={() =>
                                          clickItem(
                                            value.attributes.Link,
                                            value.attributes.File?.data != null
                                              ? value.attributes.File.data
                                                .attributes.url
                                              : value.attributes.File.data
                                          )
                                        }
                                        type="button"
                                      >
                                        {value.attributes.Anchor}
                                      </button>
                                    ) : null}
                                  </List>
                                );
                              })}
                              {/* </UnorderedList> */}
                            </div>
                          </section>
                        </Tab.Panel>
                        <Tab.Panel>
                          <section className="my-10">
                            <H2 className="mb-5 text-blue">
                              Un-Audited Financial Statements & Statement of Revenues and Expenses
                            </H2>
                            <div className="financial-statement-columns">
                              {/* <UnorderedList> */}
                              {unAuditedStatements.map((value) => {
                                return (
                                  <List
                                    className="text-orange whitespace-nowrap"
                                    key={value.attributes.createdAt}
                                  >
                                    {value.attributes.Anchor != null ? (
                                      <button
                                        className="text-left"
                                        onClick={() =>
                                          clickItem(
                                            value.attributes.Link,
                                            value.attributes.File?.data != null
                                              ? value.attributes.File.data
                                                .attributes.url
                                              : value.attributes.File.data
                                          )
                                        }
                                        type="button"
                                      >
                                        {value.attributes.Anchor}
                                      </button>
                                    ) : null}
                                  </List>
                                );
                              })}
                            </div>
                          </section>
                        </Tab.Panel>
                        <Tab.Panel>
                          <section className="my-10">
                            <H1 className="mb-5 text-blue">
                              SELF-INSURANCE PROTOCOLS
                            </H1>
                            <a
                              className="text-orange"
                              href="https://admin.guampowerauthority.com/uploads/selfinsuranceprotocol_Final_Nov62015_ba9c56f320.pdf"
                              rel="noreferrer"
                              target="_blank"
                            >
                              Self insurance protocol
                            </a>
                          </section>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="my-10">
                    <H1 className="mb-5 text-blue">
                      Citizen Centric Report (P.L. 30-127)
                    </H1>
                    <section className="my-5" id="citizen-centric-reports">
                      <div className="financial-statement-columns">
                        {/* <UnorderedList> */}
                        {citizenCentricReports.map((value) => {
                          return (
                            <List
                              className="text-orange whitespace-nowrap"
                              key={value.attributes.createdAt}
                            >
                              {value.attributes.Anchor != null ? (
                                <button
                                  className="text-left"
                                  onClick={() =>
                                    clickItem(
                                      value.attributes.Link,
                                      value.attributes.File?.data != null
                                        ? value.attributes.File.data.attributes
                                          .url
                                        : value.attributes.File.data
                                    )
                                  }
                                  type="button"
                                >
                                  {value.attributes.Anchor}
                                </button>
                              ) : null}
                            </List>
                          );
                        })}
                        {/* </UnorderedList> */}
                      </div>
                    </section>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="my-10">
                    <section className="my-10 tablet:my-10 financial-statement-columns">
                      {annualReport.map((value) => {
                        return (
                          <div
                            className="grid cols-auto grid-cols-1 tablet:grid-cols-2 gap-y-4 content-end tablet:content-start tablet:h-300px"
                            key={value.attributes.createdAt}
                          >
                            <div className="pr-0 tablet:pr-2 tabletWide:pr-8">
                              <img
                                alt={value.attributes.Title}
                                className="w-2/3 tablet:h-300px object-contain object-left-top tablet:w-auto"
                                src={`${API}/${value.attributes?.Thumbnail?.data?.attributes?.url}`}
                              />
                            </div>
                            <div>
                              <H3 className="mb-2 text-blue">
                                {value.attributes.Title}
                              </H3>
                              {value.attributes.Anchor != null ? (
                                <button
                                  className="text-orange text-left"
                                  onClick={() =>
                                    clickItem(
                                      value.attributes.Link,
                                      value.attributes.File?.data != null
                                        ? value.attributes.File.data.attributes
                                          .url
                                        : value.attributes.File.data
                                    )
                                  }
                                  type="button"
                                >
                                  {value.attributes.Anchor}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </section>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="my-10">
                    <H1 className="mb-5">BOND CREDIT RATING</H1>
                    <Tab.Group>
                      <Tab.List className="flex items-center justify-left border-b">
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Bond Official Statements
                            </div>
                          )}
                        </Tab>
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Credit Ratings
                            </div>
                          )}
                        </Tab>
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Operating Data (Rule 15C2-12)
                            </div>
                          )}
                        </Tab>
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                  ? "bg-blue text-white"
                                  : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Operating Data
                            </div>
                          )}
                        </Tab>
                      </Tab.List>
                      <Tab.Panels className="">
                        <Tab.Panel>
                          <div className="grid grid-cols-1 tablet:grid-cols-3 gap-x-20 tablet:gap-y-10 mt-10">
                            <div className="mb-5 tablet:mb-0">
                              <H2 className="mb-5 text-blue">
                                Bond Official Statements
                              </H2>
                              {/* <UnorderedList> */}
                              {bondStatements.map((value) => {
                                return (
                                  <List
                                    className="text-orange"
                                    key={value.attributes.createdAt}
                                  >
                                    <button
                                      className="text-left"
                                      onClick={() =>
                                        clickItem(
                                          value.attributes.Link,
                                          value.attributes.File?.data != null
                                            ? value.attributes.File.data
                                              .attributes.url
                                            : value.attributes.File.data
                                        )
                                      }
                                      type="button"
                                    >
                                      {value.attributes.Anchor}
                                    </button>
                                  </List>
                                );
                              })}
                              {/* </UnorderedList> */}
                            </div>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          <div className="grid grid-cols-1 tablet:grid-cols-3 gap-x-20 tablet:gap-y-10 mt-10">
                            {financialRatings.map((fRatings) => {
                              return (
                                <div
                                  className="mb-5 tablet:mb-0"
                                  key={fRatings.year}
                                >
                                  <H2 className="mb-5 text-blue">
                                    {`${fRatings.year} Credit ratings`}
                                  </H2>
                                  {/* <UnorderedList> */}
                                  {fRatings?.items.map((value) => {
                                    return (
                                      <List
                                        className="text-orange whitespace-nowrap"
                                        key={value.attributes.createdAt}
                                      >
                                        {value.attributes.Anchor != null ? (
                                          <button
                                            className="text-left"
                                            onClick={() =>
                                              clickItem(
                                                value.attributes.Link,
                                                value.attributes.File?.data !=
                                                  null
                                                  ? value.attributes.File.data
                                                    .attributes.url
                                                  : value.attributes.File.data
                                              )
                                            }
                                            type="button"
                                          >
                                            {value.attributes.Anchor}
                                          </button>
                                        ) : null}
                                      </List>
                                    );
                                  })}
                                  {/* </UnorderedList> */}
                                </div>
                              );
                            })}
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          <div className="grid grid-cols-1 tablet:grid-cols-3 gap-x-20 tablet:gap-y-10 mt-10">
                            <div className="mb-5 tablet:mb-0">
                              <H2 className="mb-5 text-blue" />
                            </div>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          <section className="my-10">
                            <H1 className="mb-5 text-blue">OPERATING DATA</H1>
                            <a
                              className="text-orange"
                              href="https://admin.guampowerauthority.com/uploads/FY_21_GPA_Oper_Data_Revised_38b1cbd340.pdf"
                              rel="noreferrer"
                              target="_blank"
                            >
                              FY2021 Operating Data
                            </a>
                          </section>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="my-10">
                    <H1 className="mb-5 text-blue">
                      Non-Employee Travel Justification
                    </H1>
                    <Tab.Group>
                      <Tab.List className="flex items-center justify-left border-b">
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                ? "bg-blue text-white"
                                : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Current Fiscal Year
                            </div>
                          )}
                        </Tab>
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <div
                              className={`${selected
                                ? "bg-blue text-white"
                                : "bg-lightgray text-blue"
                                } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                            >
                              Archive
                            </div>
                          )}
                        </Tab>
                      </Tab.List>
                      <Tab.Panels className="">
                        <Tab.Panel>
                          <section className="my-5" id="citizen-centric-reports">
                            <div className="financial-statement-columns">
                              {/* <UnorderedList> */}
                              {nonEmployeeTravelJustificationReportsNew.map((value) => {
                                // if(!value.attributes.Anchor.includes("2025")){
                                //   return (<></>);
                                // }
                                return (
                                  <List
                                    className="text-orange whitespace-nowrap"
                                    key={value.attributes.createdAt}
                                  >
                                    {value.attributes.Anchor != null ? (
                                      <button
                                        className="text-left"
                                        onClick={() =>
                                          clickItem(
                                            value.attributes.Link,
                                            value.attributes.File?.data != null
                                              ? value.attributes.File.data.attributes
                                                .url
                                              : value.attributes.File.data
                                          )
                                        }
                                        type="button"
                                      >
                                        {value.attributes.Anchor}
                                      </button>
                                    ) : null}
                                  </List>
                                );
                              })}
                              {/* </UnorderedList> */}
                            </div>
                          </section>
                        </Tab.Panel>
                        <Tab.Panel>
                          <section className="my-5" id="citizen-centric-reports">
                            <div className="financial-statement-columns">
                              {/* <UnorderedList> */}
                              {nonEmployeeTravelJustificationReportsArchive.map((value) => {
                                // if(value.attributes.Anchor.includes("2025")){
                                //   return (<></>);
                                // }
                                return (
                                  <List
                                    className="text-orange whitespace-nowrap"
                                    key={value.attributes.createdAt}
                                  >
                                    {value.attributes.Anchor != null ? (
                                      <button
                                        className="text-left"
                                        onClick={() =>
                                          clickItem(
                                            value.attributes.Link,
                                            value.attributes.File?.data != null
                                              ? value.attributes.File.data.attributes
                                                .url
                                              : value.attributes.File.data
                                          )
                                        }
                                        type="button"
                                      >
                                        {value.attributes.Anchor}
                                      </button>
                                    ) : null}
                                  </List>
                                );
                              })}
                              {/* </UnorderedList> */}
                            </div>
                          </section>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="my-10">
                    <H1 className="mb-5">
                      MANAGEMENT & OPERATIONAL ASSESSMENT
                    </H1>
                    <Details>
                      Baker Tilly Virchow Krause, LLP submitted this final
                      report related to the Management and Operational
                      Assessment of Guam Power Authority on September 23, 2009.
                      The final report is comprised of an executive summary and
                      nine sections covering the areas of our review. In
                      addition, the staffing and productivity study is
                      summarized in a written report.
                    </Details>
                    <div>
                      <H2 className="mb-5 text-blue">Sections</H2>
                      <div className="grid grid-cols-1 tablet:grid-cols-2 phone:gap-x-0 tablet:gap-x-20">
                        {/* <UnorderedList> */}
                        {assesmentReports.map((value) => {
                          return (
                            <List
                              className="text-orange whitespace-nowrap"
                              key={value.attributes.createdAt}
                            >
                              {value.attributes.Anchor != null ? (
                                <button
                                  className="text-left"
                                  onClick={() =>
                                    clickItem(
                                      value.attributes.Link,
                                      value.attributes.File?.data != null
                                        ? value.attributes.File.data.attributes
                                          .url
                                        : value.attributes.File.data
                                    )
                                  }
                                  type="button"
                                >
                                  {value.attributes.Anchor}
                                </button>
                              ) : null}
                            </List>
                          );
                        })}
                        {/* </UnorderedList> */}
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </section>
        </div>
      </ContentContainer>
    </>
  );
};
