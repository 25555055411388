import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface DetailsProp extends HTMLAttributes<HTMLParagraphElement> {
  className?: string;
  description?: string;
}

export const Details: FC<PropsWithChildren<DetailsProp>> = ({ children, className }, props) => {
  return (
    <p
      className={`font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg mb-5 ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};

// Text Component 
// Used to handle "\n"
export const Details2: FC<PropsWithChildren<DetailsProp>> = ({ children, className, description }, props) => {
  const tempDescription = description?.split('\n');
  return (
    <p
      className={`font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg mb-5 ${className}`}
      {...props}
    >
      {
        tempDescription?.map((textPart: string, index: number) => {
          console.log(textPart);
          // Split the sentence into two parts based on the first space
          const newTextPart1: string = textPart.includes("Afterhours")
              ? textPart.split(" ", 2)[0]
              : "";
          
          const newTextPart2: string = textPart.includes("Afterhours")
              ? textPart.slice(10, textPart.length)
              : textPart;

          return (
            <>
            {
              index > 0
              ? <br/>
              : <></>
            }
            <span className='text-blue font-normal'>
              {
                textPart.includes("Afterhours")
                ? `${newTextPart1} `
                : <></>
              }
            </span>
            <span>
              {
                textPart.includes("Afterhours")
                ? newTextPart2
                : textPart
              }
            </span>
            </>
          )
        })
      }
      &nbsp;{children}
    </p>
  );
};
