import { API } from '@config';
// import { SOPDATA } from '@src/types/SOPindex';

// export const searchGpa = async (searchString: string | null, page: number, pageSize: number) => {
export const getFOIAData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let data;

  try {
    const response = await fetch(`${API}/api/foia-reports?populate=File`, options);
    data = await response.json();

    return data;
  } catch (err) {
    // console.error(err);
  }

  return data;
};
