import { API } from '@config';
import { AboutUsGPAFactSheetTable } from '@src/types/AboutUsGPAFactSheet';

export const getAboutUsGPAFactSheet = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/about-us-gpa-fact-sheets?populate=File&sort=id%3Adesc`, options);
  const data: AboutUsGPAFactSheetTable = await response.json();

  return data;
};
