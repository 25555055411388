import React from 'react';
import { Images } from '@src/core';

export const Maintenance = () => (
  <div className="flex phone:px-5 tablet:px-10 laptop:px-20 justify-center desktop:h-4/5 items-center">
    <div className="items-center laptop:pt-32 phone:pt-32 phone:w-full pb-24">
        <div className="flex justify-center">
            <img alt="Maintenance" src={Images.MaintenanceImage} />
        </div>

      <h1
        className="font-rubik font-bold laptop:text-4xl phone:text-3xl text-primary-100
        text-center mb-2"
      >
        <span className="text-blue tracking-wide">We are under maintenance..</span>
      </h1>
      <p className="text-center">
        This service is currently undergoing maintenance.
      </p>
      <p className="mb-10 text-center">
        We should be back shortly. Thank you for your patience.
      </p>
    </div>
  </div>
);
