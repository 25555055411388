import { API } from "@config";

export const getPucFinancialsLeac = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${API}/api/financials-puc-leacs?populate=File&sort=Anchor%3Adesc`,
    options
  );
  const data = await response.json();

  return data;
};
