import {
  // OnDevelopment,
  Home,
  FormsAndPermits,
  ManageMyAccount,
  FormsAndPermitsBusiness,
  ManageMyAccountBusiness,
  PowerInYourHands,
  // WhatWereDoing,
  // OtherResources,
  // NewPowerPlantProject,
  ContactUs,
  StartStopMoveService,
  About,
  Operations,
  // Rates,
  RatesResidential,
  // RatesBusiness,
  RateCalculator,
  Financials,
  HumanResources,
  CorporateNews,
  Safety,
  Education,
  SeeWattsNew,
  // CommunityNews,
  Procurement,
  GetFinancialAssistance,
  UnderstandMyBill,
  FindJobOpenings,
  GetARebate,
  RateSchedules,
  LEAC,
  // RegionalUtility,
  IntegratedResourcePlan,
  // WhatYouCanDo,
  InformationVideos,
  Events,
  ProjectsForecastsStudies,
  RenewableResourcesLinks,
  PlanningData,
  PlanningDocumentArchive,
  AnticipatedProjects,
  PersonnelServices,
  EmployeeBenefits,
  Certifications,
  Demographics,
  RetirementPlans,
  MedicalDentalBenefit,
  LifeInsuranceBenefit,
  PaidHolidays,
  CafeteriaPlan,
  PayCodes,
  Petitions,
  Leaves,
  WaysToPay,
  SearchPage,
  SpecialPrograms,
  ApprenticeshipeProgramPage,
  TrainingAndDevelopmentPage,
  // GPAInitiatives,
  WhatYouCanDo,
  // NewPowerPlantProject,
  CleanEnergyMasterPlan,
  OtherResources,
  TrackMyEnergyConsumption,
  GPAInitiatives,
  InternetPolicy,
  SOPIndex,
  InOurCommunity,
  CommunityPartersAndPrograms,
  BrighterDaysAhead,
  FOIAReports,
  // BrighterDaysAhead,
} from "@pages";
import { BeatThePeak } from "@src/pages/BeatThePeak";
import Milestone from "@src/pages/Milestone";
// import PotentialRotatingOutageSched from "@src/pages/PotentialRotatingOutageSched";

export const Navigations = [
  {
    navigation: "How do I...",
    routes: [
      // {
      //   routeName: 'See Scheduled Outages',
      //   link: '/how-do-i/see-scheduled-outages',
      //   page: OnDevelopment,
      // },
      {
        routeName: "Lower My Power Bill",
        link: "https://energysense.guampowerauthority.com/savings-tips",
        page: "",
      },
      {
        routeName: "Pay a Bill",
        link: "/ways-to-pay",
        page: WaysToPay,
      },
      {
        routeName: "Understand My Bill",
        link: "/how-do-i/understand-my-bill",
        page: UnderstandMyBill,
      },
      {
        routeName: "Track my Energy Consumption",
        link: "/how-do-i/track-my-energy-consumption",
        page: TrackMyEnergyConsumption,
      },
      {
        routeName: "View or Report Outages",
        link: "/power-alerts",
        page: PowerInYourHands,
      },
      // {
      //   routeName: 'Set Up an Online Account',
      //   link: '',
      //   page: '',
      // },
      {
        routeName: "Start, Stop & Move Services",
        link: "/how-do-i/start-stop-move-services",
        page: StartStopMoveService,
      },
      {
        routeName: "Make a Payment Arrangement",
        link: "/how-do-i/get-financial-assistance",
        page: GetFinancialAssistance,
      },
      {
        routeName: "Get Financial Assistance",
        link: "/how-do-i/get-financial-assistance",
        page: GetFinancialAssistance,
      },
      {
        routeName: "Get Customer Support",
        link: "/contact-us",
        page: ContactUs,
      },
      {
        routeName: "Get A Rebate",
        link: "https://gparebates.com/",
        page: GetARebate,
      },
      {
        routeName: "Find Job Openings",
        link: "/how-do-i/find-job-openings",
        page: FindJobOpenings,
      },
      {
        routeName: "File a Damage Claim",
        link: "https://admin.guampowerauthority.com/uploads/DAMAGE_CLAIM_FORM_and_info_10_NOV_2020_c4e6f35eeb.pdf",
        page: FindJobOpenings,
      },
      {
        routeName: "See Watts New",
        link: "/news-and-media",
        page: SeeWattsNew,
      },
      {
        routeName: "Beat the Peak",
        link: "/beatthepeak",
        page: BeatThePeak,
      },
      // {
      //   routeName: "Brighter Days Ahead",
      //   link: "/brighterdaysahead",
      //   page: BrighterDaysAhead,
      // },
    ],
  },
  {
    navigation: "Residential Customers",
    routes: [
      {
        routeName: "Manage My Account",
        link: "/residential/manage-my-account",
        page: ManageMyAccount,
      },
      {
        routeName: "Rebates for my Home",
        link: "https://energysense.guampowerauthority.com/rebates/air-conditioning",
        page: FormsAndPermits,
      },
      {
        routeName: "Forms and Permits",
        link: "/residential/forms-and-permits",
        page: FormsAndPermits,
      },
      {
        routeName: "Residential Rates",
        link: "/rates",
        page: RatesResidential,
      },
    ],
  },
  {
    navigation: "Business Customers",
    routes: [
      {
        routeName: "Manage My Account",
        link: "/business/manage-my-account",
        page: ManageMyAccountBusiness,
      },
      {
        routeName: "Rebates for my Business",
        link: "https://energysense.guampowerauthority.com/rebates/air-conditioning",
        page: FormsAndPermits,
      },
      {
        routeName: "Forms And Permits",
        link: "/business/forms-and-permits",
        page: FormsAndPermitsBusiness,
      },
      {
        routeName: "Business Rates",
        link: "/rates",
        page: RatesResidential,
      },
    ],
  },
  {
    navigation: "Rate Information",
    routes: [
      {
        routeName: "Rates",
        link: "/rates",
        page: RateSchedules,
      },
      {
        routeName: "Levelized Energy Adjustment Clause (LEAC)",
        link: "/leac",
        page: LEAC,
      },
      // {
      //   routeName: 'Regional Comparison',
      //   link: '/regional-comparison',
      //   page: RegionalUtility,
      // },
      {
        routeName: "Rate Calculator",
        link: "/rate-calculator",
        page: RateCalculator,
      },
    ],
  },
  {
    navigation: "In Our Community",
    routes: [
      {
        routeName: "In Our Community",
        link: "/in-our-community",
        page: InOurCommunity,
      },
      {
        routeName: "Educational Resources",
        link: "/in-our-community/educational-resources",
        page: Education,
      },
      {
        routeName: "Safety",
        link: "/in-our-community/safety",
        page: Safety,
      },

      {
        routeName: "Subscribe to our E-Newsletter",
        link: "/in-our-community/#e-newsletter",
        page: Education,
      },
      {
        routeName: "American Public Power Association (APPA) Article",
        link: "https://www.publicpower.org/periodical/article/guam-power-authority-bolsters-resilience-and-charts-path-50-renewables?fbclid=IwAR3IhENr_5FTL8cYtMVaH1oKfbz8BZfpbKGkuhix-3OqJV5qBiBmNsRTuzs",
        page: Education,
      },
    ],
  },
  {
    navigation: "GPA Initiatives",
    routes: [
      {
        routeName: `GPA Initiatives`,
        link: "/gpa-initiatives",
        page: GPAInitiatives,
      },
      // {
      //   routeName: `GPA Initiatives`,
      //   link: '/gpa-initiatives',
      //   page: GPAInitiatives,
      // },
      {
        routeName: "Clean Energy Master Plan",
        link: "/gpa-initiatives/clean-energy-master-plan",
        page: CleanEnergyMasterPlan,
      },
      {
        routeName: "Power to Serve",
        link: "/corporate/operations",
        page: Operations,
      },
      {
        routeName: "Power to Save",
        link: "https://energysense.guampowerauthority.com/savings-tips",
        page: WhatYouCanDo,
      },
      // {
      //   routeName: 'Solar for my Home',
      //   link: 'https://guampowerauthority.com/energysense/rebates/air-conditioning',
      //   page: FormsAndPermits,
      // },
      // {
      //   routeName: 'Electric Vehicles',
      //   link: '',
      //   page: '',
      // },
      {
        routeName: "Rebates",
        link: "https://energysense.guampowerauthority.com/rebates/air-conditioning",
        page: "",
      },
      // {
      //   routeName: 'Power to Serve',
      //   link: '/gpa-initiatives/power-to-serve',
      //   page: WhatWereDoing,
      // },
      {
        routeName: "Other Resources",
        link: "/gpa-initiatives/other-resources",
        page: OtherResources,
      },
      {
        routeName: "Brighter Days Ahead",
        link: "/brighterdaysahead",
        page: BrighterDaysAhead,
      },
    ],
  },
  {
    navigation: "Corporate",
    routes: [
      {
        routeName: "About Us",
        link: "/corporate/about-us",
        page: About,
      },
      // {
      //   routeName: 'Maintenance',
      //   link: '/maintenance',
      //   page: Maintenance
      // },
      {
        routeName: "Customer Support",
        link: "/contact-us",
        page: ContactUs,
      },

      {
        routeName: "Operations",
        link: "/corporate/operations",
        page: Operations,
      },
      // {
      //   routeName: 'Rates',
      //   link: '/corporate/rates',
      //   page: Rates,
      // },
      {
        routeName: "Financials",
        link: "/corporate/financials",
        page: Financials,
      },
      {
        routeName: "Human Resources",
        link: "/corporate/human-resources",
        page: HumanResources,
      },
      {
        routeName: "News and Media",
        link: "/news-and-media",
        page: CorporateNews,
      },
      {
        routeName: "FOIA Reports",
        link: "/foia-reports",
        page: FOIAReports,
      },
      {
        routeName: "Procurement",
        link: "/corporate/procurement",
        page: "Procurement",
      },
    ],
  },
];

export const ProcurementDisclosureRoutes = [
  {
    routeName: "Available Tenders",
    link: "https://go.opengovguam.com/tenders/tenders_list-standalone/gpa?tender_status=Available",
    page: "",
  },
  {
    routeName: "Available Emergency Procurement",
    link: "https://go.opengovguam.com/tenders/tenders_list-standalone/gpa?tender_status=Available%20Emergency%20Procurement",
    page: "",
  },
  {
    routeName: "Protest/Stay of Procurement",
    link: "https://go.opengovguam.com/tenders/tenders_list-standalone/gpa?tender_status=Protest/Stay%20of%20Procurement",
    page: "",
  },
  {
    routeName: "Lift of Stay",
    link: "https://go.opengovguam.com/tenders/tenders_list/gpa?tender_status=Lift%20of%20Stay",
    page: "",
  },
  {
    routeName: "Awarded Tenders",
    link: "https://go.opengovguam.com/tenders/tenders_list-standalone/gpa?tender_status=Awarded",
    page: "",
  },
  {
    routeName: "Awarded Sole Source",
    link: "https://go.opengovguam.com/bids/awarded_sole_source/gpa",
    page: "",
  },
  {
    routeName: "Closed Tenders",
    link: "https://go.opengovguam.com/tenders/tenders_list-standalone/gpa?tender_status=Closed",
    page: "",
  },
  {
    routeName: "Technical Documents",
    link: "https://go.opengovguam.com/bids/tech_docs/gpa",
    page: "",
  },
  {
    routeName: "GPA Contracts",
    link: "https://go.opengovguam.com/bids/major_contract/gpa",
    page: "",
  },
  {
    routeName: "Issued Tenders",
    link: "https://go.opengovguam.com/tenders/tenders_list/gpa",
    page: "",
  },
];

export const FinancialsDisclosureRoutes = [
  {
    routeName: "Financial Statements",
    link: "/corporate/financials/#financial-statements",
    page: Financials,
  },
  {
    routeName: "Citizen-centric Reports",
    link: "/corporate/financials/#citizen-centric-reports",
    page: Financials,
  },
  {
    routeName: "Annual Reports",
    link: "/corporate/financials/",
    page: Financials,
  },
  {
    routeName: "Bond Credit Rating Reviews",
    link: "/corporate/financials/#bond-credit-rating-reviews",
    page: Financials,
  },
  {
    routeName: "Management & Operational Assessment",
    link: "/corporate/financials/#management-and-operational",
    page: Financials,
  },
];

export const SingleRoutes = [
  {
    routeName: "Integrated Resource Plan",
    link: "/corporate/news/integrated-resource-plan",
    page: IntegratedResourcePlan,
  },
  {
    routeName: "Information Videos",
    link: "/gpa-initiatives/what-were-doing/information-videos",
    page: InformationVideos,
  },
  {
    routeName: "Highlights and Significant Events",
    link: "/corporate/operations/highlights-and-significant-events",
    page: Events,
  },
  {
    routeName: "Projects, Forecasts, and Studies",
    link: "/corporate/operations/projects-forecasts-and-studies",
    page: ProjectsForecastsStudies,
  },
  {
    routeName: "Renewable Resources Links",
    link: "/corporate/operations/renewable-resources-links",
    page: RenewableResourcesLinks,
  },
  {
    routeName: "Planning Data",
    link: "/corporate/operations/planning-data",
    page: PlanningData,
  },
  {
    routeName: "Planning Document Archive",
    link: "/corporate/operations/planning-document-archive",
    page: PlanningDocumentArchive,
  },
  {
    routeName: "Anticipated Projects",
    link: "/corporate/operations/anticipated-projects",
    page: AnticipatedProjects,
  },
  {
    routeName: "Procurement",
    link: "/corporate/procurement",
    page: Procurement,
  },
  {
    routeName: "Personnel Services",
    link: "corporate/human-resources/personnel-services",
    page: PersonnelServices,
  },
  {
    routeName: "Employee Benefits",
    link: "corporate/human-resources/employee-benefits",
    page: EmployeeBenefits,
  },
  {
    routeName: "Certifications",
    link: "corporate/human-resources/certifications",
    page: Certifications,
  },
  {
    routeName: "Special Programs",
    link: "corporate/human-resources/special-programs",
    page: SpecialPrograms,
  },
  {
    routeName: "Apprentice Program",
    link: "corporate/human-resources/special-programs/apprenticeship-program",
    page: ApprenticeshipeProgramPage,
  },
  {
    routeName: "Training And Development",
    link: "corporate/human-resources/special-programs/training-and-development",
    page: TrainingAndDevelopmentPage,
  },
  {
    routeName: "Demographics",
    link: "corporate/human-resources/demographics",
    page: Demographics,
  },
  {
    routeName: "Retirement Plans",
    link: "corporate/human-resources/retirement-plans",
    page: RetirementPlans,
  },
  {
    routeName: "Benefits",
    link: "corporate/human-resources/benefits",
    page: MedicalDentalBenefit,
  },
  {
    routeName: "Life Insurance Benefit",
    link: "corporate/human-resources/life-insurance-benefit",
    page: LifeInsuranceBenefit,
  },
  {
    routeName: "Paid Holidays",
    link: "corporate/human-resources/paid-holidays",
    page: PaidHolidays,
  },
  {
    routeName: "The Cafeteria Plan",
    link: "corporate/human-resources/the-cafeteria-plan",
    page: CafeteriaPlan,
  },
  {
    routeName: "Pay Codes",
    link: "corporate/human-resources/pay-codes",
    page: PayCodes,
  },
  {
    routeName: "Petitions",
    link: "corporate/human-resources/petitions",
    page: Petitions,
  },
  {
    routeName: "Work, Sick, & Maternity Leave",
    link: "corporate/human-resources/leaves",
    page: Leaves,
  },
  {
    routeName: "Internet Policy",
    link: "/internet-policy",
    page: InternetPolicy,
  },
  {
    routeName: "SOP Index",
    link: "/sop-index",
    page: SOPIndex,
  },
  {
    routeName: "Community Partners and Programs",
    link: "/in-our-community/community-partners-and-programs",
    page: CommunityPartersAndPrograms,
  },
  // {
  //   routeName: "Potential Rotating Outage Schedule",
  //   link: "/potential-rotating-outage-schedule",
  //   page: PotentialRotatingOutageSched,
  // },
  {
    link: "/milestones",
    page: Milestone,
  },
];

export const DefaultsRoutes = [
  {
    routeName: "Home",
    link: "/",
    page: Home,
  },
];

export const SearchPageRoute = [
  {
    routeName: "Search Result",
    link: "search",
    page: SearchPage,
  },
];
