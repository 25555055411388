// import React, { useEffect, useState } from 'react';
import React from 'react';
// import HomepageBanner from "@assets/videos/GPA_Launch_HomeBanner.mp4";
import { BDAIcons, BDAImages } from '@core';
import { HeaderGradient } from '@src/components';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';
// import { HomeLearnMoreItem } from "@src/components";

export const BrighterDaysAhead = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  // const getData = async () => {
  //   const fileDatas = await getFileData();
  //   setFile(fileDatas.data);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-misc-orange phone:text-center phoneWide:text-left"
        rightColor="to-misc-yellow"
        title="Addressing Capacity Shortfall"
      />
      <div
        className="relative h-96"
        style={{
          minHeight: '350px',
          backgroundImage: `url(${BDAImages.BDABanner})`,
          backgroundPosition: 'left',
          backgroundSize: 'cover',
        }}
      >
        <div className="hidden absolute w-full">
          <HeaderGradient
            leftColor="from-misc-orange"
            rightColor="to-misc-yellow"
            title="Addressing Capacity Shortfall"
          />
        </div>
        <div className="flex justify-center items-center h-full phone:flex-col tabletWide:flex-row tabletWide:justify-start">
          <div className="flex flex-col justify-center h-full tabletWide:justify-start tabletWide:items-start phone:items-center phoneLg:ml-0 phone:px-10 tablet:px-12 laptop:px-20 tabletWide:mt-20">
            <h1 className="mb-1 font-bold text-white font-jubilat phone:text-lg tablet:text-6xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left">
              Brighter Days
            </h1>
            <h1 className="mb-1 font-bold text-white font-jubilat phone:text-lg tablet:text-6xl laptop:text-6xl desktop:text-6xl phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left">
              Ahead
            </h1>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <section className="flex flex-col w-full tabletWide:flex-row">
        {/* Grid Section */}
        <div className="p-4 w-full phoneLg:py-12 phoneLg:px-12 laptop:px-20 tabletWide:w-2/3 laptop:w-3/4">
          <div className="py-4 mx-auto w-full text-center h-300px laptopSm:h-400px laptop:h-500px laptopSm:py-8">
            <div className="w-full h-full">
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="w-full h-full"
                frameBorder="0"
                // height="720"
                src="https://www.youtube.com/embed/DqwxPSs7hjg"
                title="GPA Brighter Days Ahead - How GPA is Bridging Guam's Energy Gap until 2025"
                width="1280"
              />
            </div>
          </div>

          <p className="mb-4">At Guam Power Authority, we value our customers.</p>
          <p className="mb-4">
            We've overcome challenges together, and ask for your continued support as we work to
            ensure a reliable power supply.
          </p>
          <p className="mb-4">
            We're addressing issues by boosting capacity and exploring solutions to bridge the gap
            until the completion of the state-of-the-art Ukudu Power Plant by September 30, 2025.
          </p>
          <p className="mb-4">
            We’re procuring temporary power and repairing generators and every effort aims to keep
            lights on for you.
          </p>
          <p className="mb-8">
            And with your continued support, GPA works toward brighter days ahead!
          </p>

          <p className="mb-2 text-xl font-bold font-jubilat tablet:text-2xl laptopSm:text-3xl">
            198MW Ukudu Power Plant progress
          </p>
          <p>Completion by September 2025</p>

          <div className="grid grid-cols-1 gap-2 my-4 w-full tablet:grid-cols-2 laptop:grid-cols-3">
            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.overAllPlant})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Actual accumulated progress including Engineering, Procurement, and Construction:
                88.35% as of June 30, 2024.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.controlElectricalBuilding})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">Control Electrical Building June 2024.</p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.HRSGImage})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Installation of the Inlet Duct Insulation Steel Structure and casing is ongoing.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.gtgBuilding})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Cable pulling and termination work is ongoing.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.treatedWaterTanks})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">Treated Water Storage Tanks:</p>
              <ul>
                <li>Treated Water Tank A - 5 Courses in place</li>
                <li>Treated Water Tank B - 6 Courses in place</li>
              </ul>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.steamTubinePiping})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">Steam Turbine piping work is ongoing.</p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.stgPiping})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Steam Turbine & Generator Building – Piping installation work is ongoing.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.ulsdTanks})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                ULSD Storage Tanks Hydro testing work is completed for both ULSD Tanks. Construction
                of containment walls is ongoing.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.coolingTower})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Cooling Tower & Basin - Corrugated panel and Fan Deck installation is ongoing.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.fireWaterTanks})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Fire Fighting Tank: Fire tank has been completed and filled with water.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.route1Pipeline})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Route 1 Piti Tank Farm Pipeline installation is ongoing along route 1 towards the
                Piti Tank Farm Installation work is also ongoing within the Piti Tank Farm.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.route16Pipeline})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Route 16 Pipeline installation is nearly completed. Backfilling and road restoration
                is ongoing.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.nimitzHillPipeline})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Nimitz Hill - Above ground pipeline installation is in progress.
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg1})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Treated Water Storage Tanks - Staging of tank walls is in progress
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg2})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Heat Recovery Steam Generator (HRSG) - Insulation repair and replacement is ongoing
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg3})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Gas Turbines and Generators (GTG) - Installation of air intakes is ongoing
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg4})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                GTG Building - Exterior siding installation is nearly complete
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg5})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Demineralized Water Tank - Stair construction is completed
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg6})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                BESS - Excavation for the battery control building foundation is ongoing
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg7})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Cooling Tower & Basin - Cooling Tower frame installation is nearly complete
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg8})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Switchyard - Ring bus structures are in place
              </p>
            </div>

            <div className="p-2">
              <div
                className="h-48 phoneWide:h-80 tabletWide:h-60 bg-gray"
                style={{
                  backgroundImage: `url(${BDAImages.BDAGridImg9})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
              <p className="mt-2 italic text-center">
                Control & Electric Bldg - Fire protection and external HVAC installation is in
                progress
              </p>
            </div>
          </div>
        </div>

        {/* Work Progress Section */}
        <div className="p-4 w-full phoneLg:p-12 tabletWide:p-8 laptopSm:p-12 tabletWide:w-1/3 laptop:w-1/4 bg-yellow2 tabletWide:flex tabletWide:flex-col tabletWide:justify-start tabletWide:space-y-12">
          <p className="mb-8 text-xl font-jubilat tablet:text-2xl laptopSm:text-3xl">
            Addressing Generation Capacity Shortfall
          </p>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon1})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">Piti 7 Repair of the Plenum</p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>December 3, 2023
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>22MW to 30MW
              </p>
              <div className="flex flex-row justify-start items-center my-2">
                <div
                  className="mr-2 w-10 h-10"
                  style={{
                    backgroundImage: `url(${BDAIcons.BDAIcon15})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                <div className="w-3/4">
                  <p className="font-bold">COMPLETED AHEAD OF SCHEDULE 11/30</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon2})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">Utilizing Existing Battery Energy Storage System (ESS)</p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>Available to use in Feb 2024
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>16 MWH to be utilized during peak
                hours
              </p>
              <div className="flex flex-row justify-start items-center my-2">
                <div
                  className="mr-2 w-10 h-10"
                  style={{
                    backgroundImage: `url(${BDAIcons.BDAIcon15})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                <div className="w-3/4">
                  <p className="font-normal">Currently In Use</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon3})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">Repairing the Typhoon-Damaged Yigo Combustion Turbine</p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>April 2024
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>20 MW
              </p>
              <p>
                <span className="mr-2 text-blue">
                  * Currently operating at 16MW pending further maintenance.
                </span>
              </p>
              <div className="flex flex-row justify-start items-center my-2">
                <div
                  className="mr-2 w-10 h-10"
                  style={{
                    backgroundImage: `url(${BDAIcons.BDAIcon15})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                <div className="w-3/4">
                  <p className="font-normal">Currently In Use</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon6})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">Returning Caterpillar and Wartsila Units to Service</p>
              <p className="italic">(Tenjo, Talo’fo’fo & Manenggon/Pulantat)</p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>May 2024
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>16 MW
              </p>
              <p>
                <span className="mr-2 text-blue">*10 of 16MW Completed.</span>
              </p>
              <div className="flex flex-row justify-start items-center my-2">
                <div
                  className="mr-2 w-10 h-10"
                  style={{
                    backgroundImage: `url(${BDAIcons.BDAIcon15})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                <div className="w-3/4">
                  <p className="font-normal">Currently In Use</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon4})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">Procuring Temporary Power</p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>July 2024
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>20 MW
              </p>
              <div className="flex flex-row justify-start items-center my-2">
                <div
                  className="mr-2 w-10 h-10"
                  style={{
                    backgroundImage: `url(${BDAIcons.BDAIcon15})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                <div className="w-3/4">
                  <p className="font-normal">Currently In Use</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon5})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">
                Increasing Existing Cummins diesel units located at Yigo site
              </p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>Sept. 2024
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>14 MW
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon7})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">
                Restoring Capacity for Yigo Diesel units to be placed adjacent to the Tenjo Vista
                Plant
              </p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>September 2024
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>20 MW
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center my-2">
            <div
              className="w-14 h-14"
              style={{
                backgroundImage: `url(${BDAIcons.BDAIcon8})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="w-3/4">
              <p className="font-bold">Continuing to Expand Interruptible Load Capacity</p>
              <p>
                <span className="mr-2 font-bold">ECD:</span>Ongoing
              </p>
              <p>
                <span className="mr-2 font-bold">Capacity:</span>
                <span className="italic">Approximately 16 MW currently</span>
              </p>
            </div>
          </div>

          <p className="italic">*ECD is Expected Completion Date</p>

          {/* Extra Component */}
          <div className="hidden h-96 tabletWide:flex laptop:hidden" />
        </div>
      </section>
    </>
  );
};
