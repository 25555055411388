import React, { Fragment, useEffect, useState } from "react";
import { H1, H3, Details, Content as ContentContainer, Images, Details2 } from "@core";
import {} from "@content";
import { HeaderGradient } from "@components";
import { CONTACTUSBUSINESSHOURS } from "@src/types/ContactUsBusinessHours";
import { CONTACTUSCONTACTGPA } from "@src/types/ContactUsContactGPA";
import { CONTACTUSDEPARTMENTFAXES } from "@src/types/ContactUsDepartmentFax";
import { CONTACTUSDEPARTMENTNUMBERS } from "@src/types/ContactUsDepartmentNumbers";
import { CONTACTUSGENERATIONADMINISTRATION } from "@src/types/ContactUsGenerationAdministration";
import { CONTACTUSGENERATIONADMINISTRATIONFAX } from "@src/types/ContactUsGenerationAdministrationFax";
import { CONTACTUSMAILINGADDRESSES } from "@src/types/ContactUsMailingAddresses";
import { CONTACTUSTRANSMISSIONDISTRIBUTION } from "@src/types/ContactUsTransmissionDistribution";
import { getBusinessHours } from "@src/services/ContactUsBusinessHours";
import { getContactGPA } from "@src/services/ContactUsContactGPA";
import { getDepartmentFax } from "@src/services/ContactUsDepartmentFax";
import { getDepartmentNumbers } from "@src/services/ContactUsDepartmentNumbers";
import { getGenerationAdministration } from "@src/services/ContactUsGenerationAdministration";
import { getGenerationAdministrationFax } from "@src/services/ContactUsGenerationAdministrationFax";
import { getMailingAddresses } from "@src/services/ContactUsMailingAddresses";
import { getTransmissionDistribution } from "@src/services/ContactUsTransmissionDistribution";

export const ContactUs = () => {
  const [businessHours, setBusinessHours] = useState<CONTACTUSBUSINESSHOURS[]>(
    []
  );
  const [contactGPA, setContactGPA] = useState<CONTACTUSCONTACTGPA[]>([]);
  const [departmentFax, setDepartmentFax] = useState<
    CONTACTUSDEPARTMENTFAXES[]
  >([]);
  const [departmentNumbers, setDepartmentNumbers] = useState<
    CONTACTUSDEPARTMENTNUMBERS[]
  >([]);
  const [generationAdministration, setGenerationAdministration] = useState<
    CONTACTUSGENERATIONADMINISTRATION[]
  >([]);
  const [generationAdministrationFax, setGenerationAdministrationFax] =
    useState<CONTACTUSGENERATIONADMINISTRATIONFAX[]>([]);
  const [mailingAddresses, setMailingAddresses] = useState<
    CONTACTUSMAILINGADDRESSES[]
  >([]);
  const [transmissionDistribution, setTransmissionDistribution] = useState<
    CONTACTUSTRANSMISSIONDISTRIBUTION[]
  >([]);

  // Single Data for Email
  const [contactGPAEmail, setContactGPAEmail] = useState<CONTACTUSCONTACTGPA>();

  const getData = async () => {
    const businessHoursData = await getBusinessHours();
    const contactGPAData = await getContactGPA();
    const departmentFaxData = await getDepartmentFax();
    const departmentNumbersData = await getDepartmentNumbers();
    const generationAdministrationData = await getGenerationAdministration();
    const generationAdministrationFaxData =
      await getGenerationAdministrationFax();
    const mailingAddressesData = await getMailingAddresses();
    const transmissionDistributionData = await getTransmissionDistribution();

    setBusinessHours(businessHoursData.data);
    setDepartmentFax(departmentFaxData.data);
    setDepartmentNumbers(departmentNumbersData.data);
    setGenerationAdministration(generationAdministrationData.data);
    setGenerationAdministrationFax(generationAdministrationFaxData.data);
    setMailingAddresses(mailingAddressesData.data);
    setTransmissionDistribution(transmissionDistributionData.data);

    // Temp Code for Manipulating Grid Items
    // Currently not in use
    if(contactGPAData && contactGPAData.data.length !== 0){
      const filteredDataWithoutEmailData = contactGPAData.data.filter((obj: CONTACTUSCONTACTGPA) => obj.id !== 999999);
      setContactGPA(filteredDataWithoutEmailData);
      const filteredDataWithEmailData = contactGPAData.data.filter((obj: CONTACTUSCONTACTGPA) => obj.id === 999999);
      if(filteredDataWithEmailData){
        setContactGPAEmail(filteredDataWithEmailData[0]);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Customer Support"
      />
      <section className=" relative ">
        <div className="absolute h-full bg-black/50 " />
        <div
          className="h-full w-full laptop:bg-center bg-left bg-cover bg-ContactUS bg-no-repeat flex flex-col items-center tablet:items-start justify-center 
          laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute"
        >
          <div className=" tablet:w-500px phone:w-full flex flex-col tablet:items-start phone:items-center">
            <h1
              className="font-rubik font-bold text-white tabletWide:text-5xl phoneWide:text-3xl phone:text-lg
              tablet:text-left phone:text-center"
            >
              Sign up for our
              <br />
              e-newsletter!
            </h1>
          </div>
          <a
            className="mt-2 phoneMd:mt-4 tabletWide:mt-8"
            href="/in-our-community/#e-newsletter"
          >
            <button
              className="py-1 px-4 bg-orange rounded-full w-full phoneWide:w-auto text-white text-bold tablet:px-6 tablet:py-3 phone:text-sm"
              type="button"
            >
              Connect with us
            </button>
          </a>
        </div>
        <img
          alt="News And Media Banner"
          className="aspect-ratio__16-5"
          src={Images.CustomerSupportBanner}
        />
        {/* <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" /> */}
        {/* <a
          className="absolute see-watts-new__connect-with-us "
          href="/in-our-community/#e-newsletter"
        >
          <button
            className="py-1 px-4 bg-orange rounded-full w-full phoneWide:w-auto text-white text-bold tablet:px-6 tablet:py-3 phone:text-sm"
            type="button"
          >
            Connect with us
          </button>
        </a> */}
      </section>
      <ContentContainer>
        <section id="contact-us">
          <H1>CONTACT GPA</H1>
          <div className="phone:flex phone:flex-wrap phoneLg:grid phoneLg:grid-cols-2 tablet:grid-cols-3 gap-x-20 mt-5 tablet:mb-8">
            {contactGPA.map((content) => {
              return (
                <div key={content.attributes.createdAt}>
                  <H3>{content.attributes.Title}</H3>
                  <Details2 description={content.attributes.Description}>
                    {content.attributes.Anchor != null ? (
                      <a
                        className="text-orange"
                        href={content.attributes.Link}
                        rel="noreferrer"
                        // target={content.attributes.Link === "/contact-us#hours-and-locations" ? "_blank" : ""}
                        target={
                          content.attributes.Link === "https://paygpa.com/"
                            ? "_blank"
                            : "_parent"
                        }
                      >
                        {content.attributes.Anchor}
                      </a>
                    ) : null}
                  </Details2>
                </div>
              );
            })}
          </div>
          {
            contactGPAEmail
              ? <div key={contactGPAEmail.attributes.createdAt}>
                <H3>{contactGPAEmail.attributes.Title}</H3>
                <Details2 description={contactGPAEmail.attributes.Description}>
                  {contactGPAEmail.attributes.Anchor != null ? (
                    <a
                      className="text-orange"
                      href={contactGPAEmail.attributes.Link}
                      rel="noreferrer"
                      // target={content.attributes.Link === "/contact-us#hours-and-locations" ? "_blank" : ""}
                      target={
                        contactGPAEmail.attributes.Link === "https://paygpa.com/"
                          ? "_blank"
                          : "_parent"
                      }
                    >
                      {contactGPAEmail.attributes.Anchor}
                    </a>
                  ) : null}
                </Details2>
              </div>
              : <></>
          }
        </section>
        <section className="bg-shadow laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:p-20 tablet:p-12 phone:p-7">
          <H1>Department Numbers</H1>
          <div className="flex flex-col  tablet:grid tablet:grid-cols-3 gap-x-5 tablet:gap-x-10 tabletWide:gap-x-20 mb-5">
            {departmentNumbers.map((content) => {
              return (
                <div key={content.attributes.createdAt}>
                  <H3>{content.attributes.Title}</H3>
                  <Details>
                    {content.attributes.Description}&nbsp;
                    {content.attributes.Anchor != null ? (
                      <a
                        className="text-orange"
                        href={content.attributes.Link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.attributes.Anchor}
                      </a>
                    ) : null}
                  </Details>
                </div>
              );
            })}
          </div>
        </section>
        <section className="phone:flex phone:flex-wrap tablet:grid grid-cols-2 tablet:grid-cols-3 gap-x-20 mt-5 mb-8">
          <div>
            <H1>Department Fax Numbers</H1>
            {departmentFax.map((content) => {
              return (
                <Fragment key={content.id}>
                  <H3>{content.attributes.Title}</H3>
                  <Details>
                    {content.attributes.Description}&nbsp;
                    {content.attributes.Anchor != null ? (
                      <a
                        className="text-orange"
                        href={content.attributes.Link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.attributes.Anchor}
                      </a>
                    ) : null}
                  </Details>
                </Fragment>
              );
            })}
          </div>
          <div>
            <H1>Generation Administration</H1>
            {generationAdministration.map((content) => {
              return (
                <Fragment key={content.id}>
                  <H3>{content.attributes.Title}</H3>
                  <Details>
                    {content.attributes.Description}&nbsp;
                    {content.attributes.Anchor != null ? (
                      <a
                        className="text-orange"
                        href={content.attributes.Link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.attributes.Anchor}
                      </a>
                    ) : null}
                  </Details>
                </Fragment>
              );
            })}
          </div>
          <div>
            <H1>Generation Administration Fax Numbers</H1>
            {generationAdministrationFax.map((content) => {
              return (
                <Fragment key={content.id}>
                  <H3>{content.attributes.Title}</H3>
                  <Details>
                    {content.attributes.Description}&nbsp;
                    {content.attributes.Anchor != null ? (
                      <a
                        className="text-orange"
                        href={content.attributes.Link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.attributes.Anchor}
                      </a>
                    ) : null}
                  </Details>
                </Fragment>
              );
            })}
          </div>
          <div>
            <H1>Transimission & Distribution</H1>
            {transmissionDistribution.map((content) => {
              return (
                <Fragment key={content.id}>
                  <H3>{content.attributes.Title}</H3>
                  <Details>
                    {content.attributes.Description}&nbsp;
                    {content.attributes.Anchor != null ? (
                      <a
                        className="text-orange"
                        href={content.attributes.Link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.attributes.Anchor}
                      </a>
                    ) : null}
                  </Details>
                </Fragment>
              );
            })}
          </div>
          <div>
            <H1>Transportation</H1>
            <Details>(671) 300-8319/20</Details>
          </div>
        </section>

        <section id="hours-and-locations">
          <div className="flex flex-col tabletWide:flex-row">
            <div className="tabletWide:w-1/2">
              <H1 className="text-blue">Business Hours and Locations</H1>
              {businessHours.map((content) => {
                return (
                  <div key={content.id}>
                    <H3>{content.attributes.Title}</H3>
                    <Details className="tabletWide:pr-80 md:p-0 lg:p-0">
                      {/* {content.attributes.Description.indexOf('Closed') > -1 ? (
                        <>
                          {content.attributes.Description.substring(
                            0,
                            content.attributes.Description.indexOf('Closed'),
                          )}
                          <br />
                        </>
                      ) : (
                        content.attributes.Description
                      )}
                      {content.attributes.Description.indexOf('Closed') > -1
                        ? content.attributes.Description.substring(
                            content.attributes.Description.indexOf('Closed'),
                          )
                        : null}
                      &nbsp;
                      {content.attributes.Anchor != null ? (
                        <a
                          className="text-orange"
                          href={content.attributes.Link}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.attributes.Anchor}
                        </a>
                      ) : null} */}

                      {content.attributes.Description.split("\n").map(
                        (desc) => {
                          return (
                            <>
                              {desc}
                              <br />
                            </>
                          );
                        }
                      )}
                    </Details>
                  </div>
                );
              })}
            </div>
            <div className="tabletWide:w-1/2">
              <H1 className="text-black">Mailing Addresses</H1>
              {mailingAddresses.map((content) => {
                return (
                  <div key={content.id}>
                    <H3>{content.attributes.Title}</H3>
                    <Details className="tabletWide:pr-96 md:p-0 lg:p-0">
                      {content.attributes.Description}&nbsp;
                      {content.attributes.Anchor != null ? (
                        <a
                          className="text-orange"
                          href={content.attributes.Link}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.attributes.Anchor}
                        </a>
                      ) : null}
                    </Details>
                  </div>
                );
              })}
              {/* <div>
                <H2>{MailingAddresses.addressesArray[0].subTitle}</H2>
                <Details>{MailingAddresses.addressesArray[0].address}</Details>
              </div>
              <div>
                <div className="mb-5">
                  <H2 className="inline">{MailingAddresses.addressesArray[1].subTitle}</H2>
                  <p
                    className="inline leading-loose font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg"
                  >
                    &nbsp;{MailingAddresses.addressesArray[1].address}
                    <br className="hidden phoneMd:inline" />
                    {MailingAddresses.addressesArray[1].address2}
                  </p>
                </div>
              </div>
              <div>
                <H2>{MailingAddresses.addressesArray[2].subTitle}</H2>
                <Details>{MailingAddresses.addressesArray[2].address}</Details>
              </div> */}
            </div>
          </div>
        </section>
      </ContentContainer>
    </>
  );
};
