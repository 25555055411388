import React, { useEffect, useState } from 'react';
import { H1, Details, Content as ContentContainer, UnorderedList, List } from '@core';

import { HeaderGradient } from '@components';
import { getFOIAData } from '@src/services';
import { SOPDATA } from '@src/types/SOPindex';
import { API } from '@src/config';

export const FOIAReports = () => {
  const [reports, setReports] = useState<SOPDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFOIAData();

    setReports(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Freedom of Information Act"
      />
      <ContentContainer>
        <section id="contact-us">
          <H1>FOIA Reports</H1>
          <Details className="flex flex-col">
            {/* {sop?.map((sopItem) => {
              return (
                <a
                  className="text-orange"
                  href={`${API}${sopItem.attributes.File.data.attributes.url}`}
                  key={sopItem.id}
                  rel="noreferrer"
                  target="_blank"
                >
                  {sopItem.attributes.Title}
                </a>
              );
            })} */}
            <UnorderedList>
              {reports?.map((value) => {
                if (value.attributes.File.data.attributes.url !== '') {
                  return (
                    <List
                      className="text-orange mb-4"
                      key={value.attributes.File.data.attributes.url}
                    >
                      <a
                        href={`${API}${value.attributes.File.data.attributes.url}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {value.attributes.Title}
                      </a>
                    </List>
                  );
                }

                return (
                  <>
                    {/* {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.anchor)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="text-orange" key={value.anchor}>
                                    <a
                                      className="text-orange"
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {value.anchor}
                                    </a>
                                  </List>
                                </>
                              );
                            })} */}
                  </>
                );
              })}
            </UnorderedList>
            {
                reports === null
                ? <p className="">
                    No data to be displayed
                </p>
                : <></>
            }
          </Details>
        </section>
      </ContentContainer>
    </>
  );
};
