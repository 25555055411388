import React, { Fragment, useEffect, useState } from "react";
import {
  Content as ContentContainer,
  Details,
  H1,
  H2,
  Images,
  List,
  Table,
  TableCell as TD,
  UnorderedList,
} from "@core";
import { IRP, IRPLatest, IRPLinks } from "@content";
import { HeaderGradient } from "@src/components";
import { GPAINITIATIVESIRP } from "@src/types/GpaInitiativesIrp";
import { GPAINITIATIVESIRPSUMMARY } from "@src/types/GpaInitiativesIrpSummary";
import { getGpaInitiativesIrp1 } from "@src/services/GpaInitiativesIrp1";
import { getGpaInitiativesIrp2 } from "@src/services/GpaInitiativesIrp2";
import { getGpaInitiativesIrp3 } from "@src/services/GpaInitiativesIrp3";
import { getGpaInitiativesIrpSummary } from "@src/services/GpaInitiativesIrpSummary";
import { API } from "@src/config";
// import { getFileData } from '@src/services';
// import { FILEDATA } from '@src/types';
// import { API } from '@src/config';

export const CleanEnergyMasterPlan = () => {
  const [irp1, setirp1] = useState<GPAINITIATIVESIRP[]>([]);
  const [irp2, setirp2] = useState<GPAINITIATIVESIRP[]>([]);
  const [irp3, setirp3] = useState<GPAINITIATIVESIRP[]>([]);

  const [irpSummary, setIrpSummary] = useState<GPAINITIATIVESIRPSUMMARY[]>([]);

  const getData = async () => {
    const irp1Data = await getGpaInitiativesIrp1();
    const irp2Data = await getGpaInitiativesIrp2();
    const irp3Data = await getGpaInitiativesIrp3();
    const irpSummaryData = await getGpaInitiativesIrpSummary();

    setirp1(irp1Data.data);
    setirp2(irp2Data.data);
    setirp3(irp3Data.data);
    setIrpSummary(irpSummaryData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Clean Energy Master Plan"
      />
      {/* BEFORE */}
      {/* <ContentContainer className="bg-cempCarouselItemBg1 bg-no-repeat bg-cover bg-center">
        <section className="laptop:h-1/2 w-full flex items-center tabletWide:flex-row phone:flex-col ">
          <div className="max-w-2xl w-auto mr-auto flex flex-col justify-center laptopSm:w-3/5 tabletWide:w-3/6 laptop:p-14 phone:p-2">
            <h2 className="font-rubik font-medium text-white laptop:text-5xl phoneWide:text-4xl phone:text-2xl">
              Learn about our path to 100% renewables by 2045.
            </h2>
          </div>
          <div className=" h-64 phoneWide:h-96 w-full overflow-hidden object-cover object-center tabletWide:w-3/6 tabletWide:flex tabletWide:items-center">
            <div className="youtube-video__container--2">
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="youtube-video__iframe"
                frameBorder="0"
                height="720"
                src="https://www.youtube.com/embed/TwlmVXmSk34"
                title="GPA's Clean Energy Master Plan"
                width="1280"
              />
            </div>
          </div>
        </section>
      </ContentContainer> */}
      {/* <div className="carousel-item relative w-full h-full">
        <ContentContainer className="bg-blue flex flex-col relative">
          <div className="border-solid border-4 border-white px-10 py-5">
            <h1 className="font-jubilat font-bold text-white phoneMd:text-2xl tablet:text-4xl">
              We've reached 12% Renewable Energy
            </h1>
            <img
              alt="Renewable Energy Progress"
              className="object-cover -ml-2 height-full tabletWide:-mt-24 phone:-mt-3 phone:w-full phone:h-full laptopSm:-ml-4"
              src={Images.RenewableEnergyProgressBar}
            />
            <div className="-mt-5">
              <a
                href="https://admin.guampowerauthority.com/uploads/GPA_Customer_Directory_Guam_Webz_FEB_2023_966494ae6a.pdf?updated_at=2023-02-08T06:16:58.309Z"
                rel="noreferrer"
                target="_blank"
              >
                <button
                  className="bg-orange rounded-full text-white text-bold phone:text-xs phone:py-2 phone:px-3 phone:mt-10 phoneLg:py-2 phoneLg:px-3 tabletWide:py-2 tabletWide:px-4 tabletWide:text-lg tabletWide:-mt-10"
                  type="button"
                >
                  See Our Progress
                </button>
              </a>
            </div>
          </div>
        </ContentContainer>
      </div> */}
      <div
        className="carousel slide carousel-fade carousel-dark relative w-full carousel__height--2"
        data-bs-ride="carousel"
        id="carouselDarkVariant"
      >
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
          <button
            // aria-current="true"
            aria-label="Slide 1"
            className="active carousel-buttons"
            data-bs-slide-to="0"
            data-bs-target="#carouselDarkVariant"
            type="button"
          />
          <button
            aria-label="Slide 1"
            className="carousel-buttons"
            data-bs-slide-to="1"
            data-bs-target="#carouselDarkVariant"
            type="button"
          />
        </div>

        <div className="carousel-inner relative w-full h-full overflow-hidden">
          <div className="carousel-item active relative w-full h-full">
            <ContentContainer className="bg-CEMPProgressBackground bg-no-repeat bg-cover bg-center relative h-full flex justify-center items-center">
              <section className="laptop:h-full w-full">
                <div className="border-solid border-4 border-white phone:px-5 tablet:px-10 py-5">
                  <h1 className="font-jubilat font-bold text-white laptop:text-5xl phoneWide:text-4xl phone:text-2xl">
                    We've reached 16% Renewable Energy
                  </h1>
                  <div className="phone:object-cover phone:h-96 laptopSm:hidden">
                    <img
                      alt="Renewable Energy Progress"
                      className="object-contain phone:-ml-2 height-full tabletWide:-mt-24 phone:-mt-8 phone:w-full phone:h-full laptopSm:-ml-4"
                      src={Images.RenewableEnergyProgressBarIncremented}
                    />
                  </div>
                  <img
                    alt="Renewable Energy Progress"
                    className="phone:hidden laptopSm:block laptopSm:object-contain -ml-4 laptopSm:height-full tabletWide:mt-8 phone:-mt-3 "
                    src={Images.RenewableEnergyProgressBarIncremented}
                  />
                  <div className="mt-12">
                    <a
                      href="https://admin.guampowerauthority.com/uploads/CEMP_Progress_Update_565c88398c.jpeg?updated_at=2023-03-30T02:43:53.773Z"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button
                        className="bg-orange rounded-full text-white text-bold phone:text-xs phone:py-2 phone:px-3 phone:mt-10 phoneLg:py-2 phoneLg:px-3 tabletWide:py-2 tabletWide:px-4 tabletWide:text-lg tabletWide:-mt-10"
                        type="button"
                      >
                        See 2028 Guam's Energy Future
                      </button>
                    </a>
                  </div>
                </div>
              </section>
            </ContentContainer>
          </div>
          <div className="carousel-item relative w-full h-full">
            <ContentContainer className="bg-cempCarouselItemBg1 bg-no-repeat bg-cover bg-center h-full">
              <section className="laptop:h-full w-full flex items-center tabletWide:flex-row phone:flex-col ">
                <div className="max-w-2xl w-auto mr-auto flex flex-col justify-center laptopSm:w-3/5 tabletWide:w-3/6 laptop:p-14 phone:p-2">
                  <h2 className="font-rubik font-medium text-white laptop:text-5xl phoneWide:text-4xl phone:text-2xl">
                    Learn about our path to 100% renewables by 2045.
                  </h2>
                </div>
                <div className="h-96 w-full overflow-hidden object-cover object-center tabletWide:w-3/6 tabletWide:flex tabletWide:items-center">
                  <div className="youtube-video__container--2">
                    <iframe
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      className="youtube-video__iframe"
                      frameBorder="0"
                      height="720"
                      src="https://www.youtube.com/embed/TwlmVXmSk34"
                      title="GPA's Clean Energy Master Plan"
                      width="1280"
                    />
                  </div>
                </div>
              </section>
            </ContentContainer>
          </div>
        </div>
      </div>

      <ContentContainer>
        <section className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <a
              href="https://www.nrel.gov/state-local-tribal/guam-renewable-energy.html?utm_medium=marketing&utm_source=slt&utm_campaign=guam100"
              rel="noreferrer"
              target="_blank"
            >
              <H1 className="hover:underline">Guam 100 Initiative</H1>
            </a>
            <H1 className="mb-5 uppercase">
              GPA Clean Energy Master Plan, UN SDGs, and Justice40
            </H1>
            <Details>
              The Guam Power Authority’s Clean Energy Master Plan (CEMP) is a
              comprehensive plan for transitioning Guam from legacy fossil fuel
              fired generation to renewable energy and non-greenhouse gas
              emissions electric energy supply. The Clean Energy Master Plan is
              a living document and is continuously being updated. GPA is
              committed to providing 50% of the island’s electric power from
              renewable or non greenhouse gas emissions sources by 2030 and 100%
              by 2040.
            </Details>
            <Details>
              The CEMP is a balanced plan including targets for renewable
              energy, energy resilience and security, energy efficiency, grid
              transformation, energy affordability, digital transformation, and
              transportation electrification. The CEMP is comprised of the
              following volumes or topic areas:
            </Details>
            <UnorderedList>
              <List>
                Volume 1 – Generation System Reliability, Adequacy and
                Resiliency
              </List>
              <List>Volume 2 – Generation Expansion Plan</List>
              <List>
                Volume 3 – Addendum to the 2018 Environmental Strategic Plan
              </List>
              <List>Volume 4 – Demand Side Management Plan</List>
              <List>Volume 5 – Medium Range Distribution Plan</List>
              <List>Volume 6 – Information/Operational Technology Plan</List>
              <List>Volume 7 – Strategic Plan</List>
              <List>Volume 8 – Electric Vehicle Road Map</List>
              <List>Volume 9 – Net Metering Plan</List>
            </UnorderedList>
            <Details>
              The CEMP supports the following United Nations’ Sustainable
              Development Goals (SDG):
            </Details>
            <UnorderedList>
              <List>
                SDG #7 - Ensure access to affordable, reliable, sustainable and
                modern energy for all
              </List>
              <List>
                SDG #13 - Take urgent action to combat climate change and its
                impacts
              </List>
            </UnorderedList>
            <div className="w-full flex flex-row items-center justify-center">
              <div className="max-w-xl flex flex-row items-center space-x-4 py-8">
                <div>
                  <img alt="Goal-13" src={Images.CleanEnergyGoal13} />
                </div>
                <div>
                  <img alt="Goal-07" src={Images.CleanEnergyGoal07} />
                </div>
              </div>
            </div>
            <Details>
              The CEMP also supports the federal Justice40 Initiative. Section
              223 of the Biden Administration Executive Order 14008 established
              the Justice40 Initiative directing 40% of the overall benefits of
              certain Federal investments in clean energy and energy efficiency;
              clean transit; affordable and sustainable housing; training and
              workforce development; the remediation and reduction of legacy
              pollution; and the development of clean water infrastructure – to
              flow to disadvantaged communities (DACs). Guam is a disadvantaged
              community.
            </Details>
            <Details>
              Driven by its response to the U.S. U.S. Department of Energy’s
              Justice40 Policy Priorities, Guam Power Authority is developing
              grant proposals to obtain federal infrastructure grant funding
              for: affordable clean energy, energy efficiency, transportation
              electrification, clean energy training and workforce development,
              and the remediation and reduction of legacy pollution to support
              funding GPA’s energy transition. The Table below illustrates GPA’s
              Response to the U.S. Department of Energy’s Justice40
            </Details>

            <Table>
              <tr>
                <TD>
                  Decrease energy burden in disadvantaged communities (DACs)
                </TD>
                <TD>Electric energy cost reduction</TD>
                <TD>
                  Achieve a 50% reduction in LEAC (electricity fuel charge) by
                  replacing energy production from high and volatile priced fuel
                  oil sources with price stable lower cost renewable energy
                  sources.
                </TD>
              </tr>
              <tr>
                <TD>Decrease environmental exposure and burdens for DACs</TD>
                <TD>Reduce hazadous air pollutants (HAP) and GHG emissions</TD>
                <TD>
                  Achieve an additional 37% reduction in HAP and GHG emissions
                  by replacing fossil fuel based electric energy production with
                  zero- emission renewable energy by 2030
                </TD>
              </tr>
              <tr>
                <TD>
                  Increase parity in clean energy technology access and adoption
                  in DACs
                </TD>
                <TD>
                  Clean renewable energy is shared by everyone. Access to clean
                  energy technology is made more affordable.
                </TD>
                <TD>
                  Grow the GPA Energy Sense Rebate Program from cumulative to
                  date about $9M rebates by at least another $15M by 2030
                </TD>
              </tr>

              <tr>
                <TD>Increase access to low-cost capital in DACs</TD>
                <TD>
                  Reduce the cost for investments in energy efficient appliances
                  to improve their affordability.
                </TD>
                <td className="bg-lightgray" rowSpan={3}>
                  <div className="h-full flex flex-col">
                    <div className="h-auto flex-1 border-b border-white  flex items-center justify-center p-5">
                      Grow the GPA Energy Sense Rebate Program from cumulative
                      to date about $9M rebates by at least another $15M by 2030
                    </div>
                    <div className="h-auto  flex items-center justify-center py-2 px-5">
                      Facilitate STEM Education including renewable energy
                      technology, energy efficiency technology, and energy
                      engineering.
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <TD>
                  Increase clean energy enterprise creation and contracting in
                  DACs
                </TD>
                <TD>
                  Incentivize the market for energy efficient equipment,
                  renewable energy, and energy reduction services.
                </TD>
              </tr>
              <tr>
                <TD>
                  Increase clean energy jobs, job pipeline, and job training for
                  individuals from DACs
                </TD>
                <TD>Increase clean energy jobs</TD>
              </tr>

              <tr>
                <TD>Increase energy resiliency in DACs</TD>
                <TD>
                  Improve energy reliability, resiliency, affordability, and
                  power quality for GPA customers.
                </TD>
                <TD>
                  Execute GPA's Clean Energy Master Plan. Continue to pursue new
                  technologies to improve GPA grid operations and energy
                  affordability.
                </TD>
              </tr>
              <tr>
                <TD>Increase energy democracy in DACs</TD>
                <TD>Clean renewable energy is shared by everyone.</TD>
                <TD>
                  Achieve goal of 50% of energy production from renewable energy
                  sources by 2030.
                </TD>
              </tr>
              <tr />
            </Table>
            <br />
            <Details>
              GPA is working with several National Laboratories on follow up
              work to the GPA Clean Energy Master Plan:
            </Details>
            <UnorderedList>
              <List>Argonne National Laboratory (ANL)</List>
              <List>Lawrence Berkeley National Laboratory (LBNL)</List>
              <List>Lawrence Livermore National Laboratory (LLNL)</List>
              <List>National Renewable Energy Laboratory (NREL)</List>
              <List>Pacific Northwest National Laboratory (PNNL)</List>
              <List>Sandia National Laboratory (SNL)</List>
            </UnorderedList>
          </div>
        </section>
      </ContentContainer>

      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <H1>{IRP.title}</H1>
              {IRP.contents.map((content) => {
                return (
                  <Fragment key={content.title}>
                    <H2 className="text-blue mt-10">{content.title}</H2>
                    <Details>{content.description}</Details>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return (
                          <List className="leading-4" key={value.anchor}>
                            {value.anchor}
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </Fragment>
                );
              })}
            </section>
            <section>
              <div className="mt-8" />
              <Details>
                {IRPLatest.contents.map((content) => {
                  return (
                    // <>
                    //   {file
                    //     .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                    //     .map((fileData) => {
                    //       return (
                    //         <>
                    //           <span className="text-orange">
                    //             <a
                    //               href={`${API}${fileData.attributes.File.data.attributes.url}`}
                    //               rel="noreferrer"
                    //               target="_blank"
                    //             >
                    //               {fileData.attributes.AnchorText}
                    //             </a>
                    //           </span>
                    //         </>
                    //       );
                    //     })}
                    // </>
                    <span className="text-orange" key={content.link}>
                      <a
                        href={`${API}${content.link}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.anchor}
                      </a>
                    </span>
                  );
                })}
              </Details>
            </section>

            <section>
              <H2 className="text-blue mt-10">Stakeholder Meetings</H2>
              <Details>
                The IRP process integrates stakeholders to review and discuss
                the assumptions, results and recommendations presented during
                the IRP development. GPA initiated the 2021 Integrated Resource
                Plan with a virtual Stakeholder Kickoff Meeting held in January
                2021. Partcipants included representatives from Navy, Mayor's
                Council, University of Guam and Red Cross. The Kick Off meeting
                focused on process, drivers and assumptions for this new IRP.
                Presentation files are accessible by the links provided in the
                table below:
              </Details>
            </section>
            <section>
              <div className="text-center flex flex-col justify-center">
                <H2 className="text-blue mt-14 tabletWide:w-3/4 laptop:w-2/3 desktop:w-1/3 self-center">
                  2022 IRP STAKEHOLDER MEETING SUMMARY
                </H2>
              </div>
              <Table className="flex justify-center tabletWide:px-32 laptop:px-48">
                <tr>
                  <TD className="text-left" rowType="heading">
                    Meeting
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Date
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Time
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Topics
                  </TD>
                </tr>
                {irpSummary.map((content, index) => {
                  const normalizedDate = new Date(content.attributes.Date);
                  const dateOption: Intl.DateTimeFormatOptions = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  const startDate = new Date(
                    `January 1, 1999 ${content.attributes.Start_Time}`
                  ).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });
                  const endDate = new Date(
                    `January 1, 1999 ${content.attributes.End_Time}`
                  ).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });

                  return (
                    <tr key={content.attributes.createdAt}>
                      <TD>{index + 1}</TD>
                      <TD>
                        {content.attributes.Topics === "Final Report Summary"
                          ? "TBD"
                          : normalizedDate.toLocaleDateString(
                            undefined,
                            dateOption
                          )}
                      </TD>
                      <TD>
                        {startDate !== "Invalid Date" ||
                          endDate !== "Invalid Date"
                          ? `${startDate} - ${endDate}`
                          : "TBD"}
                      </TD>
                      <TD>{content.attributes.Topics}</TD>
                    </tr>
                  );
                })}
                <Details className="text-left text-blue">
                  *Page Updated{" "}
                  {new Date(
                    irpSummary[0]?.attributes?.updatedAt
                  ).toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </Details>
              </Table>
            </section>
            <section className="my-10 tablet:my-20">
              <div className="text-center">
                <H2 className="text-blue mt-14">
                  2021 IRP STAKEHOLDER MEETING NO. 1
                </H2>
                <H2 className="text-blue">January 29, 2021</H2>
              </div>
              <Table className="flex justify-center tabletWide:px-32 laptop:px-48">
                <tr>
                  <TD className="text-left" rowType="heading">
                    Presentation No.
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Topic
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Presentation File
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Presentation Video
                  </TD>
                </tr>

                {irp1.map((value, index) => {
                  return (
                    <tr key={value.attributes.createdAt}>
                      <TD>{`1 - ${index}`}</TD>
                      <TD>{value.attributes.Topic}</TD>
                      <TD>
                        {value.attributes.Presentation_File.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${value.attributes.Presentation_File.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {value.attributes.Anchor_File}
                          </a>
                        ) : (
                          <Details>{value.attributes.Anchor_File}</Details>
                        )}
                      </TD>
                      <TD>
                        {value.attributes.Presentation_Video.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${value.attributes.Presentation_Video.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {value.attributes.Anchor_Video}
                          </a>
                        ) : (
                          <Details>{value.attributes.Anchor_File}</Details>
                        )}
                      </TD>
                    </tr>
                  );
                })}
              </Table>
            </section>
            <section className="my-10 tablet:my-20">
              <div className="text-center">
                <H2 className="text-blue mt-14">
                  2021 IRP STAKEHOLDER MEETING NO. 2
                </H2>
                <H2 className="text-blue">March 26, 2021</H2>
              </div>
              <Table className="flex justify-center tabletWide:px-32 laptop:px-48">
                <tr>
                  <TD className="text-left" rowType="heading">
                    Presentation No.
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Topic
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Presentation File
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Presentation Video
                  </TD>
                </tr>
                {irp2.map((value, index) => {
                  return (
                    <tr key={value.attributes.createdAt}>
                      <TD>{`2 - ${index}`}</TD>
                      <TD>{value.attributes.Topic}</TD>
                      <TD>
                        {value.attributes.Presentation_File.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${value.attributes.Presentation_File.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {value.attributes.Anchor_File}
                          </a>
                        ) : (
                          <Details>{value.attributes.Anchor_File}</Details>
                        )}
                      </TD>
                      <TD>
                        {value.attributes.Presentation_Video.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${value.attributes.Presentation_Video.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {value.attributes.Anchor_Video}
                          </a>
                        ) : (
                          <Details>{value.attributes.Anchor_Video}</Details>
                        )}
                      </TD>
                    </tr>
                  );
                })}
              </Table>
              <Details className="text-center">
                * Note: Network issues affected sound and video quality of some
                of the presentations during this meeting.
              </Details>
            </section>
            <section className="my-10 tablet:my-20">
              <div className="text-center">
                <H2 className="text-blue mt-14">
                  2021 IRP STAKEHOLDER MEETING NO. 3
                </H2>
                <H2 className="text-blue">December 10, 2021</H2>
              </div>
              <Table className="flex justify-center tabletWide:px-32 laptop:px-48">
                <tr>
                  <TD className="text-left" rowType="heading">
                    Presentation No.
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Topic
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Presentation File
                  </TD>
                  <TD className="text-left" rowType="heading">
                    Presentation Video
                  </TD>
                </tr>
                {irp3.map((value, index) => {
                  return (
                    <tr key={value.attributes.createdAt}>
                      <TD>{`3 - ${index}`}</TD>
                      <TD>{value.attributes.Topic}</TD>
                      <TD>
                        {value.attributes.Presentation_File.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${value.attributes.Presentation_File.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {value.attributes.Anchor_File}
                          </a>
                        ) : (
                          <Details>{value.attributes.Anchor_File}</Details>
                        )}
                      </TD>
                      <TD>
                        {value.attributes.Presentation_Video.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${value.attributes.Presentation_Video.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {value.attributes.Anchor_File}
                          </a>
                        ) : (
                          <Details>{value.attributes.Anchor_File}</Details>
                        )}
                      </TD>
                    </tr>
                  );
                })}
              </Table>
            </section>
            <section className="mt-10 tablet:mt-20">
              <H1>{IRPLinks.title}</H1>
              <UnorderedList>
                {IRPLinks.ul.map((value) => {
                  return (
                    <List key={value.p1}>
                      {value.p1}
                      <a
                        className="text-orange"
                        href={value.link1}
                        target="blank"
                      >
                        {value.anchor1}
                      </a>
                      {value.p2}
                      <a
                        className="text-orange"
                        href={value.link2}
                        target="blank"
                      >
                        {value.anchor2}
                      </a>
                    </List>
                  );
                })}
              </UnorderedList>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
