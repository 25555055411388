import { getWebsiteNotificationData } from '@src/services/getWebsiteNotificationData'
import React, { useEffect, useState } from 'react'
import { FaInfoCircle} from 'react-icons/fa'
import { IoClose } from "react-icons/io5";
import  DOMPurify from 'dompurify';

const WebsiteNotification = () => {

  const [shouldDisplay, setShouldDisplay] = useState(false)
  const [description, setDescription] = useState("")

  const getData = async () => {
    const descData = await getWebsiteNotificationData();
    if(descData?.data.length > 0){
        const desc = descData.data[0].attributes.description
        setDescription(desc)
        setShouldDisplay(true)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
    {
        shouldDisplay 
        ?
        <div className='p-3 bg-blue flex flex-row justify-between text-[14px] text-white font-thin items-center'>
            <div className='flex flex-row laptopSm:ml-16 tablet:ml-8 phoneLg:ml-4 items-center'>    
                <div><FaInfoCircle className='mr-4' size="24px" /></div>
                <div dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(description)}} />
            </div>
            <button onClick={() => setShouldDisplay(false)} type='button' >
                 <IoClose className='laptopSm:mr-16 tablet:mr-8 phoneLg:mr-4 ml-6' size="20px" />
            </button>
        </div>
        :<></>
    }
    </>
  )
}

export default WebsiteNotification