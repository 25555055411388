import { API } from "@config";

export const getNonEmployeeTravelJustificationReports = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${API}/api/non-employee-travel-justification-reports?populate=File&sort=id%3Adesc`,
    options
  );
  const data = await response.json();

  return data;
};
